import { useState, FormEvent, ChangeEvent } from 'react'

import { generateSecurityCode } from '../services/securityCodeService';

interface FormData {
  email: string
}

const useSecurityCode = () => {
  const [formData, setFormData] = useState<FormData>({
    email: '',
  })

  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState<Partial<FormData>>({})

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    generateSecurityCode(formData.email)

    setSuccess(true)
  }

  return {
    errors,
    formData,
    handleSubmit,
    handleChange,
    success
  }
}

export default useSecurityCode