import React from 'react';

import "./EmptyView.scss";

function EmptyView({text, styles}) {

    return (
        <div className="emptyView" style={styles}>
            {text ? text : "No Items"}
        </div>
    );
}

export default EmptyView;
