import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


// MUI
import LoadingButton from "@mui/lab/LoadingButton";
import {
  TextField,
  Typography,
  Grid,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem }  from "@mui/material";

// Layout
import ShadowBox from "../../layoutComponents/shadowBox/ShadowBox";
import Modal from "../../utilComponents/modal/Modal.js";

// Components
import PortfolioProjectForm from "../../components/portfolioProjectForm/PortfolioProjectForm";
import NameInput from "../../components/inputs/NameInput";
import EmailInput from "../../components/inputs/EmailInput";
import CodewarsSettings from '../../components/codewarsSettings/CodewarsSettings.js';

import "./AccountSettings.scss";

const AccountSettings = ({ orgConfig, setLoggedIn }) => {
  let history = useHistory();

  const [playerInfo, setPlayerInfo] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cohort, setCohort] = useState("");
  const [codewars, setCodewars] = useState("");
  const [leetcode, setLeetcode] = useState("");
  const [github, setGithub] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastNote, setToastNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorsArr, setErrorsArr] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  useEffect(() => {
      if(playerInfo){
          setName(playerInfo?.name);
          setEmail(playerInfo?.email);
          setCohort(playerInfo?.cohort);
          setLeetcode(playerInfo?.leetcodeid);
          setGithub(playerInfo?.github_username);
      } else {
          const requestOptions = {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            };

            fetch(`${process.env.REACT_APP_API}/users/profile`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                  history.push("/login");
                } else {
                  setPlayerInfo(data);
                }
              })
              .catch((err) => {
                console.log(err);
                history.push("/login");
              });
          }
  }, [playerInfo])

    const handleSubmit = () => {
      setLoading(true);

       const trimmedName = name.trim();
       const trimmedEmail = email.trim();
       const trimmedLeetcode= leetcode.trim();
       const trimmedGithub = github.trim();


      const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json',
                      'Authorization': `Bearer ${ localStorage.getItem('accessToken')}`
                  },
          body: JSON.stringify({ name: trimmedName, email : trimmedEmail, cohort, leetcode:trimmedLeetcode, github: trimmedGithub})
      };

      fetch(`${process.env.REACT_APP_API}/users/${playerInfo.id}`, requestOptions)
      .then(res => res.json())
      .then((result) => {
          setLoading(false);
          setPlayerInfo(result);
          setToastNote("Your account information has been updated.")
          setShowToast(true);
      }).catch(err => {
          console.error(err);
          setLoading(false);
          setToastNote("There was an error. Please try again later.")
          setShowToast(true);
      })
      .catch((err) => {
        console.log(err);
        history.push("/login");
      });
    }

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "cohort":
        setCohort(value);
        break;
      case "leetcode":
        setLeetcode(value);
        break;
      case "github":
        setGithub(value);
        break;
      default:
        break;
    }
  };

  const handleDeleteClick = async () => {
    setLoading(true);

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    fetch(`${process.env.REACT_APP_API}/users/${playerInfo.id}`, requestOptions)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Issue deleting: " + res.status);
      })
      .then((result) => {
        setLoading(false);
        localStorage.removeItem("accessToken");
        setLoggedIn(false);
        history.push({
          pathname: "/",
          state: {
            toastMessage: "Account successfully deleted.",
          },
        });
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setToastNote(
          "There was an error deleting your account. Please try again later."
        );
        setShowToast(true);
      });
  };


  return (
      <div className="accountSettings">
        <Modal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          title={"Delete Account?"}
        >
          <Typography component={"p"}>
            <strong>Warning: </strong>Deleting your account cannot be undone. This
            action is permanent. Are you sure you want to delete your account?
          </Typography>
          <hr />
          <div className="deleteAcct__submit">
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => setShowDeleteModal(false)}
            >
              Go back.
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              color="error"
              onClick={handleDeleteClick}
              loading={loading}
            >
              Delete my account.
            </LoadingButton>
          </div>
        </Modal>
      <ShadowBox title="Account Settings">
        <Snackbar
          open={showToast}
          autoHideDuration={2000}
          onClose={() => setShowToast(!showToast)}
          message={toastNote}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <NameInput
              value={name}
              handleChange={handleChange}
              errorsArr={errorsArr}
              setErrorsArr={setErrorsArr}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
          <EmailInput
            handleChange={handleChange}
            errorsArr={errorsArr}
            setErrorsArr={setErrorsArr}
            orgConfig={orgConfig}
            value={email}
          />
          </Grid>
          {orgConfig.classes && (
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Your Class</InputLabel>
                <Select
                  variant="outlined"
                  required
                  name="cohort"
                  value={cohort}
                  label="Your Class"
                  id="cohort"
                  onChange={handleChange}
                >
                  {orgConfig.classes.map((item, index) => {
                    return (
                      <MenuItem key={item + index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
                </FormControl>
            </Grid>
          )}
          <Grid item sm={6} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="leetcode"
              label="Leetcode username"
              name="leetcode"
              value={leetcode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="github"
              label="Github username"
              name="github"
              value={github}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <div className="acctInfo__submit">
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            Update
          </LoadingButton>
        </div>
      </ShadowBox>
      {playerInfo?.id && <CodewarsSettings userId={playerInfo.id}/> }
      <ShadowBox title="Portfolio Project">
        {playerInfo?.id && <PortfolioProjectForm userId={playerInfo.id} />}
      </ShadowBox>
      <ShadowBox title="Delete Account">
        <Typography component="p">
          We're sad to see you go! When you delete your account all of your data will be
          deleted. We do not keep any record of your account
          information. Your account cannot be retrieved after deletion.
        </Typography>
        <div className="deleteAcct__submit">
          <LoadingButton
            type="submit"
            variant="contained"
            color="error"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Account
          </LoadingButton>
        </div>
      </ShadowBox>
    </div>
  );
};

export default AccountSettings;
