import React, { useState } from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';

// import toast
import Snackbar from '@mui/material/Snackbar';

import './TrackProjectInstructions.scss';

const styles: { [key: string]: React.CSSProperties } = {
    "li": {
        "marginBottom": "10px"
    }
}

const TrackProjectInstructions: React.FC = () => {
    const [showToast, setShowToast] = useState<boolean>(false);

    const copyToClipboard = (): void => {
        const githubWebhook: string = 'https://codewars-tracker-be.herokuapp.com/github';
        navigator.clipboard.writeText(githubWebhook);

        setShowToast(true);
    }

    return (
        <div className="trackProjectInstructions" data-testid="commitsLog__addWebhook"> 
            <Snackbar
                open={showToast}
                autoHideDuration={2000}
                onClose={() => setShowToast(false)}
                message="Copied to clipboard"
            />
            <p>
                Github is a platform for storing and sharing code - plus much more. CodeTrack will 
                track your commits to Github on a per-repo basis. It is best to add only 1-2 repos 
                that show your absolute best work. 
            </p>
            <p>
                Please do not add repos that are work from tutorials or your solutions
                from Codewars or Leetcode.
            </p>
            <p>To connect a Github repo, follow these steps:</p>
            <ol>
                <li style={styles['li']}>Log into your <a href="https://github.com/" target="_blank" rel="noopener noreferrer"><b>Github account</b></a>.</li>
                <li style={styles['li']}>Navigate to the repo that you want to connect to CodeTrack.</li>
                <li style={styles['li']}>Click on the <b>Settings</b> option in the repo menu.</li>
                <li style={styles['li']}>Scroll down to the <b>Webhooks</b> section on the left-side menu under Code and automation.</li>
                <li style={styles['li']}>Click on the <b>Add webhook</b> button.</li>
                <li style={styles['li']}>
                    In the <b>Payload URL</b> box, paste the following URL: 
                    <div style={{"fontWeight": "600", "padding": "8px"}}>
                        https://codewars-tracker-be.herokuapp.com/github
                        <FileCopyIcon className="copy-text-icon" onClick={copyToClipboard}/>
                    </div>
                </li>
                <li style={styles['li']}>Click on the green <b>Add webhook</b> button at the bottom.</li>
                <li style={styles['li']}>Open <a href='/account-settings' target="_blank" rel="noopener noreferrer"><b>your CodeTrack account settings</b></a> and add your Github username.</li>
                <li style={styles['li']}>Click on the <b>Update</b> button and you're good to go!</li>
            </ol>
            <p>You will see commits start to be tracked on <a href='/profile' target="_blank" rel="noopener noreferrer"><b>your CodeTrack Profile</b></a> the next time you push code to this repo.</p>
            <p>Still having issues connecting a Github repo to CodeTrack? Schedule a <a href="https://calendly.com/codetrack/codetrack-introductory-demo" target="_blank" rel="noopener noreferrer"><b>quick call</b></a> to get help connecting your account.</p>
        </div>
    );
}

export default TrackProjectInstructions;