// Layout Components
import PageLayout from "../layoutComponents/pageLayout/PageLayout"

// Features
import HeroBlock from "../features/heroBlock"
import CodeTrackIntroVideo from "../features/codeTrackIntroVideo"


const GuestLandingPage = () => {
    return (
        <PageLayout>
           <HeroBlock/>
           <CodeTrackIntroVideo />
        </PageLayout>
    )
};

export default GuestLandingPage;
