import React from 'react'
import PropTypes from 'prop-types'


const ErrorView: React.FC<{ error: Error }> = ({ error }) => {
  return (
    <div className="error-view">
      <p><strong>Error:</strong> {error.message}</p>
    </div>
  )
}

export default ErrorView
