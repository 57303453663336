import React from 'react'

import './ArticlePreview.scss';

interface Article {
  title: string;
  subtitle: string;
  author: string;
  authorImage: string;
  image: string;
  tags: string[];
}

const ArticlePreview: React.FC<{ article: Article }> = ({ article }) => {
  const { title, subtitle, author, authorImage, image, tags } = article;
  return (
    <div className="article-preview">
      <div className="article-preview__image">
        <img src={image} alt="Article Image" />
      </div>
      <a target="_blank" href="https://medium.com/@jordan_92311/building-a-quick-email-feature-to-nudge-users-based-on-a-sql-query-81a06204d1e6">
        <div className="article-preview__title">
          {title}
        </div>
        <div className="article-preview__content">
          <p className="article-preview__author">Written by: {author}</p>
          <p className="article-preview__subtitle">{subtitle}</p>
        </div>
        <div className="article-preview__tags">
          {tags.map((tag: string, index: number) => (
            <span key={index} className="article-preview__tag">{tag}</span>
          ))}
        </div>
      </a>
    </div>
  )
}

export default ArticlePreview