import React, { useEffect, useState } from "react";

import CodewarsChallengesCollection from "../../../components/codewarsChallengesCollection/CodewarsChallengesCollection";
import CodilityChallengesCollection from "../../../components/codilityChallengesCollection/CodilityChallengesCollection";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import "./ProblemSets.scss";
import ShadowBox from "../../../layoutComponents/shadowBox/ShadowBox";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProblemSets({ playerInfo }) {
  const { id, username, language } = playerInfo;

  let tabValue = 0;

  const [problems, setProblems] = useState([]);
  const [value, setValue] = useState(tabValue);

  useEffect(() => {
    if (username)
      fetch(`${process.env.REACT_APP_API}/codewars/${username}`)
        .then((res) => res.json())
        .then(
          (result) => {
            setProblems(result);
          },

          (error) => {
            console.log(error);
          }
        );
  }, [playerInfo]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ShadowBox className='codeChallenges' title='Code Challenges' displayDescription='flex'>
      <Tabs
        value={value}
        className='sideTabs'
        onChange={handleChange}
        orientation='vertical'
      >
        <Tab label='Intro' {...a11yProps(0)} />
        <Tab label='Beginner' {...a11yProps(1)} />
        <Tab label='Intermediate' {...a11yProps(2)} />
        <Tab label='Algorithms' {...a11yProps(3)} />
        <Tab label='Data Structures' {...a11yProps(4)} />
        <Tab label='Codility' {...a11yProps(5)} />
      </Tabs>
      <div className='challengeLists'>
        <TabPanel value={value} index={0}>
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='grasshopper'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='grasshopper-debug'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='strings1'
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='strings2'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='arraysIntro'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='hashTables'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='controlFlow'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='mathIntro'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='OOP'
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='strings3'
          />
           <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='strings4'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='arrays'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='objects'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='higherOrderFunctions'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='matrixes'
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='recursion'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='cryptography'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='bubbleSort'
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='linkedLists'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='queues'
          />
          <CodewarsChallengesCollection
            language={language}
            problems={problems}
            problemSet='trees'
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <p>
            For Codility problems, please submit the url to your submitted
            solution next to the completed problem.
          </p>
          <CodilityChallengesCollection
            userId={id}
            language={language}
            problems={problems}
            problemSet='codility'
          />
          <CodilityChallengesCollection
            userId={id}
            language={language}
            problems={problems}
            problemSet='stacksCodility'
          />
        </TabPanel>
      </div>
    </ShadowBox>
  );
}

export default ProblemSets;
