import React, {useRef, useEffect, useState} from 'react';
import CurrentProblem from './src/components/currentProblem/CurrentProblem';
import BattingHistoryList from './src/components/battingHistoryList/BattingHistoryList';
import AtBatContainer from './src/components/atBatContainer/AtBatContainer';
import Modal from './src/components/modal/Modal';

import { Button } from '@material-ui/core';

import { useCookies } from 'react-cookie';

import problemSetIntro from './src/problemSetIntro.json';
import problemSetEasy from './src/problemSetEasy.json';
import problemSetMed from './src/problemSetMed.json';
import problemSetHard from './src/problemSetHard.json';
import problemSetTesting from './src/problemSetTesting.json';

import utils from './src/utils';

import './BattingCage.scss';
import randomSelection from './src/utils/problemSelection';

let problemSetObj = {
  unset: [],
  intro: problemSetIntro,
  easy: problemSetEasy,
  medium: problemSetMed,
  hard: problemSetHard
  // testing: problemSetTesting
}

function BattingCage(props) {

    const [problemId, setProblemId] = useState(null);
    const [gameOverReport, setGameOverReport] = useState({active: false});
    const [strikes, setStrikes] = useState(0);
    const [outs, setOuts] = useState(0);
    const [score, setScore] = useState(0);
    const [battingHistory, setBattingHistory] = useState([]);
    const [gameMode, setGameMode] = useState('unset');
    const [showModal, setShowModal] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies('playedTutorial');
    const [gameStateCookie, setGameStateCookie, removeGameStateCookie] = useCookies('gameState');
    const [problemSet, setProblemSet] = useState([]);
    const [bottom, setBottom] = useState('-100px')

    const battingHist = useRef(null);

    let playedTutorial = utils.Cookie.get('playedTutorial');
    let gameState = utils.Cookie.get('gameState');

    const scrollToBottom = () => {
        battingHist.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest" // < -- remove this and the whole page will auto scroll
        });
      };

      useEffect(() => {
        // be sure we need to scroll
        if (battingHistory.length > 1) {
          scrollToBottom();
        }
      }, [battingHistory]);

    useEffect(()=> {
      setCookie("gameState", {
        problemId,
        score,
        strikes,
        gameMode,
        outs
      });
    });

    useEffect(()=> {

      let id = gameState && gameState.problemId ? problemId : 0;
      if(problemId){
        setCookie("gameState", {
          ...gameState,
          problemId: id
        });
      }
    }, [problemId]);


    useEffect(()=> {
        
      if(!playedTutorial) {
        setGameMode("intro");
      // } else if(gameState.problemId && gameState.gameMode && gameState.gameMode !== "unset") {
      //   setProblemId(gameState.problemId);
      //   setGameMode(gameState.gameMode);
      //   setScore(gameState.score);
      //   setStrikes(gameState.strikes);
      //   setOuts(gameState.outs);
      } else {
        setGameMode('unset');
      }

    }, []);
  
    useEffect(()=> {
      if(gameMode !== 'unset'){
        setStrikes(0);
        setOuts(0);
        setScore(0);
        setProblemId(0);
        if(gameMode === "intro"){
          setProblemSet(problemSetObj['intro']);
        } else {
          console.log(gameMode)
          setProblemSet(randomSelection(problemSetObj[gameMode]));
        }
      } else if (gameMode === 'unset' && problemId > 0){
        scrollToBottom();
        if(playedTutorial && gameMode !== null){
          setGameOverReport(
            { 
              title: outs > 2 ? "Try Again!" : "Great Game!",
              active: true
            }
          );
        }
      }
    }, [gameMode])

    useEffect(()=> {
        setTimeout(function(){setBottom('0')}, 1000);

        if(problemId !== null && gameMode !== "unset") {
            let testResults = problemSet[problemId].tests
            .map((_, index)=>({
                shouldDisplay: index===0,// Set random test index here
                currentlyPassing:false
            }));

        if(problemId === 0) {
          setBattingHistory([
            {
              problemId: problemId,
              result: 'batting',
              testResults: testResults,
              totalTests: problemSet[problemId].tests.length,
              hint: problemSet[problemId].hint,
              totalTestsPassed: 0,
              atBatHistory:[], 
              tests: problemSet[problemId].tests
            }
          ])
        } else {   
          setBattingHistory([
            ...battingHistory, 
            {
              problemId: problemId,
              result: 'batting',
              testResults: testResults,
              totalTests: problemSet[problemId].tests.length,
              hint: problemSet[problemId].hint,
              totalTestsPassed: 0,
              atBatHistory:[], 
              tests: problemSet[problemId].tests
            }
          ])
        }
      }

    }, [problemId])

    //Manage strikes and outs
    useEffect(() => {

      if(strikes === 3){
        if(outs === 2 || problemId === problemSet.length - 1){
          setGameMode('unset');
        } else {
          setProblemId(problemId + 1);
        }
        setOuts(outs + 1);
        setStrikes(0);
      }
    },[strikes]);

    useEffect(() => {

      if(score > 0){
        if(problemId === problemSet.length - 1){
          if(gameMode === "intro"){
            setCookie("playedTutorial", true);
            setGameOverReport({
              active: true,
              title: "Training Complete!"
            });
          }
          setGameMode('unset');
        } else {
          setProblemId(problemId + 1);
        }
      } 
    },[score]);

    const setDifficultyMode = (e) => {   
      // setGameMode('testing');
      setGameMode(e.currentTarget.getAttribute('data-difficulty'));
      setGameOverReport({active: false});
    }

    const openVideoWalkthrough = () => {
      window.open('https://www.loom.com/share/39c636bcfb1b4acca4c513a6619c337d', '_blank');
    }

    return (
        <div className="battingCage">
            <div className="battingCage__howToPlay">
              <Button 
                  variant="outlined"
                  color="primary"
                  onClick={()=>openVideoWalkthrough()}>
                    Video Walkthrough
                </Button>
                <Button 
                  variant="contained"
                  color="primary"
                  onClick={()=>setShowModal(true)}>
                    How To Play
                </Button>
            </div>
            <div className="battingCage__gameArea">
                <div className="battingCage__gameAreaHeader">
                    <div className="battingCage__title">CodeTrack Batting Cage</div>
                    <div className="battingCage__count">
                        <div>Outs : {outs}</div>
                        <div>Runs : {score}</div>
                    </div>
                    
                </div>
            <BattingHistoryList>    
              { battingHistory.length > 0 && 
                <div className="battingHistory" style={{"bottom" : bottom}}>
                  {
                    battingHistory.map(atBat => {
                      return (<AtBatContainer atBat={atBat} gameMode={gameMode} playedTutorial={playedTutorial} />)
                    })
                  }
                  
                </div>
              }
              {gameOverReport.active && 
                <div className="game-over-report">
                  <h2>{gameOverReport.title}</h2>
                  <div>
                    You scored {score} runs.
                  </div>
                  <div> With {outs} outs.</div>
                </div>
                
              }
              {gameMode === 'unset' && 
                <div className="game-intro">
                <h3>Please Pick a Mode</h3>
                <div className="game-intro--buttons">
                  <Button onClick={(e) => setDifficultyMode(e)} data-difficulty='easy' variant="contained" color="primary">Easy</Button>
                  <Button onClick={(e) => setDifficultyMode(e)} data-difficulty='medium' variant="contained" color="primary">Medium</Button>
                  <Button onClick={(e) => setDifficultyMode(e)} data-difficulty='hard' variant="contained" color="primary">Hard</Button>
                </div>
              </div>
              }
              <div ref={battingHist} className="refEl" />
            </BattingHistoryList>
            { gameMode !== 'unset' && 
              <CurrentProblem 
                strikes={strikes} 
                setStrikes={setStrikes} 
                setScore={setScore} 
                score={score} 
                battingHistory={battingHistory} 
                setBattingHistory={setBattingHistory}
                currentProblem={problemSet[problemId]}
                problemId={problemId}
              />
            } 
          </div>
          <Modal 
            show={showModal} 
            setShowModal={setShowModal}
            setGameMode={setGameMode}
            removeCookie={removeCookie}
          />
        </div>
      );
}

export default BattingCage;