import { useEffect, useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, TextField, Button, MenuItem, Select } from "@material-ui/core";
import OpenInNew from "@mui/icons-material/OpenInNew";
import EmptyView from "../emptyView/EmptyView";

export default function TodoList({ todos, fetchTodos, ghName, isProfile }) {
  const [newTodo, setNewTodo] = useState("");
  const [repoNames, setRepoNames] = useState(['CodeTrack-FE', 'CodeTrack-BE']);
  const [selectedRepo, setSelectedRepo] = useState(repoNames[0]);

  const markComplete = function (id) {
    if (!isProfile) return;
    fetch(`${process.env.REACT_APP_API}/todos/${id}/toggle`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((res) => res.json())
      .then(fetchTodos);
  };

  const addTodo = function (e) {
    e.preventDefault();
    if (!isProfile) return;
    fetch(`${process.env.REACT_APP_API}/todos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({ todo: newTodo, repo_name: selectedRepo }),
    })
      .then((res) => res.json())
      .then(() => {
        setNewTodo("");
        fetchTodos();
      });
  };

  useEffect(fetchTodos, [fetchTodos]);

  return (
    <>
      {isProfile && (
        <form onSubmit={addTodo} style={{ marginBottom: "1em", display: "flex", alignItems: "center" }}>
          <Select
            value={selectedRepo}
            onChange={(e) => setSelectedRepo(e.target.value)}
            style={{ marginRight: "1em", flexGrow: 1 }}
          >
            {repoNames.map((repo) => (
              <MenuItem key={repo} value={repo}>{repo}</MenuItem>
            ))}
          </Select>
          <TextField
            value={newTodo}
            onChange={(e) => setNewTodo(e.target.value)}
            placeholder="Add a new todo"
            style={{ marginRight: "1em", flexGrow: 2 }}
          />
          <Button type="submit" variant="contained" color="primary" style={{ width: "25%" }}>
            Add Todo
          </Button>
        </form>
      )}
      {todos.length > 0 ? (
        <List>
          {todos.map((todo) => (
            <ListItem
              disableRipple
              style={{ width: "max-content", maxWidth: "100%" }}>
              <ListItemIcon>
                <Checkbox
                  onClick={() => markComplete(todo.id)}
                  disableRipple
                  checked={todo.is_completed}
                  disabled={!isProfile}></Checkbox>
              </ListItemIcon>
              <ListItemText>
                <a
                  href={`https://github.com/${ghName}/${todo.repo_name}`}
                  rel="noreferrer"
                  target="_blank"
                  style={{ marginRight: "1em" }}>
                  <strong>{todo.repo_name}</strong>
                  <OpenInNew
                    fontSize="small"
                    style={{
                      width: ".65em",
                      height: "auto",
                      marginLeft: ".1em",
                      verticalAlign: "top",
                    }}
                  />
                </a>
                {todo.todo}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) : (
        <EmptyView text="Add a todo!" />
      )}
    </>
  );
}