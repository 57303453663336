import React from 'react';

import './Videos.scss';

function Videos() {
    return (
        <div className="videos">
            <div className='container'>

            <h3>Videos</h3>
            <div className="videos__grid">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/g9C9RJe8ATM?si=RgamDEItZNZLaIWS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/4AmJ-691KH4?si=2s4l_1ToU7L4eZ7H" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/vr5CS2NvPOY?si=mMWR8-Rfz4VvdkzN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            {/* <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/BZKK6Kahfu-Dll-Sr3soaGREr-BYcTPptaemfET7lIfQyKiD5m67e-SY8rEho2Tr.UOMQw1HWO5yNNskz?startTime=1673710354000"
                    target="_blank"
                >
                    January 14th: Reverse Words and Represent Array as Ranges
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/1QT9X-NEwGGPnrWT4fZlUsFoN2ohpyZBVDh7T_2WskaJSNTvs3sbwAIu20YDOhnx.ZNg64fjCsC7pZ68x?startTime=1673105651000"
                    target="_blank"
                >
                    January 7th: HTML & CSS with BEM and SCSS
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/HWRkf82rINMZo9h1U-VhuVf6-TRMahaVnSg_l5Y44x2jKUEVCgiDe_Q6quk85T8.J9htNeZvqVBMPkjL"
                    target="_blank"
                >
                    December 17th: Advent of Code - Day 2
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/TIjy_k8hYGjYj82XYKYthKud7wHdqaon0-efCjHlZ3Dlg4wmzUCRkuTBiWxMveCm.qiK8jwg-y9tpcDe-"
                    target="_blank"
                >
                    December 10th: Advent of Code - Day 1
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/w8cK5xvfRjmBPlZXAQxLy-TIDf-n7BS7DIcVR2tTh08SYxB7nVcQklLaxKrC3uos.Q-gLobi1DYqvfseO
        "
                    target="_blank"
                >
                    October 29th: Strings and Arrays (90 Min Class)
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/HbsJ5pAqoaJ5VemeVwfvvGyeRFvcCutxvVgKvPQM-T3vsUNJis55Tb0kMy523O_c.GkIsvgS468-LzPEl?startTime=1661124775000"
                    target="_blank"
                >
                    August 21th: Rapid Fire Exercise and Linked Lists (90 Min Class)
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/play/OZB_QxaOtYOtiTr8iTuKd9P1_Fh0tWhKH0lpMPGU7nMhjwnOC0_dBp7K1uXOEaPNHpuAKYnzbJw2JkEC.-I7EiS3jNQnOrKQF?continueMode=true&_x_zm_rtaid=SPnvGoS8SligxGYApBDHkQ.1660527255702.354f24fd860e24168246293daa45bb29&_x_zm_rhtaid=180"
                    target="_blank"
                >
                    August 14th: Inside Out Strings and Rods and Rings  (90 Min Class)
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/rD88bKcNGWhhZqQiJHJvijaAxapVQXyAlA-E3l4bUxPTikZJGfVjOTRSWXXstPZp.yHOmi88zFW8wUyIJ"
                    target="_blank"
                >
                    August 8th: Compress Strings (120 Min Class)
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/GMH3XDzXVD1bbOddeOdizfBnZiZM1p5QO8MkXynJhkYNUPXK6OxvBdK6-Y_OGkxS.R9vi8NIyKO8dixM6?startTime=1659276216000"
                    target="_blank"
                >
                    July 31st: String Shortener (90 Min Class)
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/cA4867KVME1KYRRwv-1sOE3ZPW4ulKIDBZd2NzuIUEAAZIuutqBgFeVVVIkmUF30.OI2rQ2DaFzDqzfEZ"
                    target="_blank"
                >
                    July 24th: Well of Ideas - Harder Version (90 Min Class)
                </a>
            </div> */}
        </div>
        </div>
    );
}

export default Videos;