import {useFetchStandings} from '../../hooks/useFetchStandings';
import { ResponsiveBump } from '@nivo/bump';

import EmptyView from '../emptyView/EmptyView';
import SpinningLoader from '../../layoutComponents/spinningLoader/SpinningLoader';
import ErrorView from '../../layoutComponents/errorView/ErrorView';

function BumpChart({subdomain}) {

    const {standings, isLoading, error} = useFetchStandings(subdomain);
    
    return (
        <div style={{"height": standings.length == 0 ? "auto" : "500px"}}>
            {isLoading && <SpinningLoader />}
            {error && <ErrorView error={error} />}
            {!isLoading && !error && standings.length == 0 && <EmptyView text={"No Standings Data"} />}
            {standings.length > 0 && <ResponsiveBump
            data={standings}
            colors={{ scheme: 'category10' }}
            lineWidth={3}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            pointSize={12}
            activePointSize={16}
            inactivePointSize={0}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={3}
            activePointBorderWidth={3}
            pointBorderColor={{ from: 'serie.color' }}
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -36
            }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Ranking',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
            axisRight={null}
        />}
    </div>
    )
};

export default BumpChart;