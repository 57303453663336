// Layout Components
import PageLayout from '../layoutComponents/pageLayout/PageLayout';

// Features
import LoginForm from '../features/loginForm';

const Login = ({ setLoggedIn }: { setLoggedIn: (loggedIn: boolean) => void }) => {
    return (
        <PageLayout>
            <LoginForm setLoggedIn={setLoggedIn} />
        </PageLayout>
    )
}

export default Login;