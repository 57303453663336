import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

import "./FellowDetail.scss";

import UserProfileCard from "../components/userProfileCard/UserProfileCard";
import PlayerDetailCard from "../components/playerDetailCard/PlayerDetailCard";
import Dashboard from "./studentDetailSubviews/Dashboard";
import ProblemSets from "./studentDetailSubviews/problemSets/ProblemSets";
import GithubRepos from "./studentDetailSubviews/githubRepos/GithubRepos";
import CSSChallenges from "./studentDetailSubviews/cssChallenges/CssChallenges";
import Portfolio from "./studentDetailSubviews/Portfolio";

import UserIcon from "@mui/icons-material/Dashboard";
import ChallengeIcon from '@mui/icons-material/Code';
import CssIcon from '@mui/icons-material/Css';
import SchoolIcon from "@mui/icons-material/School";
import PortfolioIcon from '@mui/icons-material/GitHub';

function FellowDetail({ subdomain, orgConfig = {}, loggedIn}) {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { fellowId } = useParams();

  const location = useLocation();
  let history = useHistory();

  const [playerInfo, setPlayerInfo] = useState(
    location?.state?.playerInfo || {}
  );

  const [subview, setSubview] = useState(
    window.location.hash ? window.location.hash.slice(1) : "dashboard"
  );

  const [isProfile, setIsProfile] = useState(location.pathname.includes("profile"));

  useEffect(() => {
    if (!isProfile) {
      fetch(`${process.env.REACT_APP_API}/users/${fellowId}`)
        .then((res) => res.json())
        .then(
          (result) => {
            setPlayerInfo(result);
          },

          (error) => {
            console.log(error);
          }
        );
    } else {

      const requestOptions = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      };

      fetch(`${process.env.REACT_APP_API}/users/profile`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
          if (data.error) {
            history.push("/login");
          } else {
            setPlayerInfo(data);
          }
        })
        .catch((err) => {
          console.log(err);
          history.push("/login");
        });
    }
  }, []);

  function handleChangeSubview(subviewName) {
    setSubview(subviewName);

    let hash = `#${subviewName}`;
    history.replace(window.location.pathname + hash);
  }

  return (
    <div className='container'>
      <div className='fellowDetailLayout'>
        <div className='sideMenu'>
          <UserIcon 
            className={"sideMenu__icon" + (subview === "dashboard" ? " sideMenu__icon--selected" : "")}
            onClick={() => handleChangeSubview("dashboard")} 
          />
          <ChallengeIcon
            className={"sideMenu__icon" + (subview === "codeChallenges" ? " sideMenu__icon--selected" : "")}
            onClick={() => handleChangeSubview("codeChallenges")}
          />
          {/* {(playerInfo.role === 'staff' || whiteListIdsFCC.includes(playerInfo.id)) && <SchoolIcon
            className={"sideMenu__icon" + (subview === "freeCodeCamp" ? " sideMenu__icon--selected" : "")}
            onClick={() => handleChangeSubview("freeCodeCamp")}
          />} */}
          {fellowId === 11 && <SchoolIcon 
            className={"sideMenu__icon" + (subview === "githubRepos" ? " sideMenu__icon--selected" : "")}
            onClick={() => handleChangeSubview("githubRepos")} 
          />}
          <CssIcon 
            className={"sideMenu__icon" + (subview === "cssChallenges" ? " sideMenu__icon--selected" : "")}
            onClick={() => handleChangeSubview("cssChallenges")} 
          />
          <PortfolioIcon 
            className={"sideMenu__icon" + (subview === "portfolio" ? " sideMenu__icon--selected" : "")}
            onClick={() => handleChangeSubview("portfolio")} 
          />
        </div>
        <div className='pageContent'>
          <PlayerDetailCard playerInfo={playerInfo} />
          <UserProfileCard playerInfo={playerInfo} />
          {subview === "dashboard" && <Dashboard playerInfo={playerInfo} />}
          {subview === "codeChallenges" && (
            <ProblemSets playerInfo={playerInfo} />
          )}
          {subview === "githubRepos" && (
            <GithubRepos playerInfo={playerInfo} />
          )}
          {subview === "cssChallenges" && (
            <CSSChallenges playerInfo={playerInfo} />
          )}
          {subview === "portfolio" && <Portfolio playerInfo={playerInfo} isProfile />}
        </div>
      </div>
    </div>
  );
}

export default FellowDetail;
