import React from 'react'
import { CircularProgress, Box } from '@mui/material'

interface SpinningLoaderProps {
  size?: number
  color?: string
}

const SpinningLoader: React.FC<SpinningLoaderProps> = ({ size = 40, color = 'primary' }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <CircularProgress size={size} color={color as 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit'} />
    </Box>
  )
}

export default SpinningLoader
