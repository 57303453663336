import React, { useEffect, useState, useCallback } from "react";

import ShadowBox from "../../layoutComponents/shadowBox/ShadowBox";
import CommitsLog from "../../components/commitsLog/CommitsLog";
import PortfolioDisplay from "../../components/portfolioDisplay/PortfolioDisplay";
import TodoList from "../../components/todoList/TodoList";

import "./StudentDetailSubviews.scss";
import "./Portfolio.scss";

function Portfolio({ playerInfo, isProfile }) {
  const [portfolioData, setPortfolioData] = React.useState({});
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [todos, setTodos] = useState([]);

  const fetchTodos = useCallback(async () => {
    if(!isProfile) return;

    const res = await fetch(`${process.env.REACT_APP_API}/todos`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.ok) {
      const json = await res.json();
      setTodos(json);
    }
  }, [setTodos]);

  useEffect(() => {
    if (playerInfo.id) {
      const api = `${process.env.REACT_APP_API}/portfolios/${playerInfo.id}`;
      fetch(api)
        .then((res) => res.json())
        .then((result) => {
          setPortfolioData(result);
        });
    }
  }, [playerInfo]);

  console.log(isProfile);

  return (
    <div className="portfolio">
      {portfolioData && Object.values(portfolioData).length > 0 && (
        <ShadowBox>
          <PortfolioDisplay
            portfolioData={portfolioData}
            setShowEditForm={setShowEditForm}
            showEditForm={showEditForm}
          />
        </ShadowBox>
      )}
      <ShadowBox title="Todo List">
        <TodoList
          todos={todos}
          fetchTodos={fetchTodos}
          ghName={playerInfo.github_username}
          isProfile
        />
      </ShadowBox>
      <ShadowBox title="Commit Tracker">
        <CommitsLog playerInfo={playerInfo} fetchTodos={fetchTodos} />
      </ShadowBox>
    </div>
  );
}

export default Portfolio;
