export const fetchLeetcodeStats = async (id: number, leetcodeId: string) => {
  try {
    const response = await fetch(`https://leetcode-stats-api.herokuapp.com/${leetcodeId}`)
    if (!response.ok) {
      throw new Error('Failed to fetch LeetCode stats')
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching LeetCode stats:', error)
    throw error
  }
}