import React, {useState} from 'react';

import Modal from '../../utilComponents/modal/Modal';

import './LogicAssessment.scss';

const LogicAssessment = () => {

    const [showModal, setShowModal] = useState(false);

    const onSubmit = () => {
        setShowModal(true);
    }

    return (
        <div className="logicAssessment">
            <Modal showModal={showModal} setShowModal={setShowModal} >
                <h2>Thank you for your answers!</h2> 
                <p>
                    On a scale of 1-5, how much would you say you enjoyed this activity?
                </p>
                <div className="logicAssessment__rating">
                    <div><input type="radio" name="rating" value="1" /> 1 - I hate this.</div>
                    <div><input type="radio" name="rating" value="2" /> 2 - I don't like this.</div>
                    <div><input type="radio" name="rating" value="3" /> 3 - It's ok.</div>
                    <div><input type="radio" name="rating" value="4" /> 4 - I like this.</div>
                    <div><input type="radio" name="rating" value="5" /> 5 - I love this!</div>
                </div>
                <button className="logicAssessment__submit logicAssessment__submit--small" onClick={onSubmit}>Submit</button>



            </Modal>
            <h2>Given the information below, please answer the three questions on the right.</h2>

            <div className="tryTwoColumns">
                <div className="logicAssessment__clues">
                    <div className="logicAssessment__clue">
                        4 -> <img src="/shapes/cylinder.png" alt="triangle" /> -> 6
                    </div>
                    <div className="logicAssessment__clue">
                        7 -> <img src="/shapes/cylinder.png" alt="triangle" /> -> 9
                    </div>
                    <div className="logicAssessment__clue">
                        3 -> <img src="/shapes/square.jpeg" alt="triangle" /> -> 2
                    </div>
                    <div className="logicAssessment__clue">
                        11 -> <img src="/shapes/square.jpeg" alt="triangle" /> -> 10
                    </div>
                    <div className="logicAssessment__clue">
                        4 -> <img src="/shapes/triangle.png" alt="triangle" /> -> 2
                    </div>
                    <div className="logicAssessment__clue">
                        5 -> <img src="/shapes/triangle.png" alt="triangle" /> -> 5
                    </div>
                    <div className="logicAssessment__clue">
                        12 -> <img src="/shapes/triangle.png" alt="triangle" /> -> 6
                    </div>
                </div>
                <div className="logicAssessment__challenges">
                    <div className="logicAssessment__challenge">
                        <div className="logicAssessment__clue">
                            4 -> <img src="/shapes/cylinder.png" alt="cylinder" /> -> <img src="/shapes/square.jpeg" alt="triangle" /> -> <img src="/shapes/cylinder.png" alt="cylinder" /> -> <input className="logicAssessment__answer" />
                        </div>
                    </div>
                    <div className="logicAssessment__challenge">
                        <div className="logicAssessment__clue">
                            7 -> <img src="/shapes/square.jpeg" alt="square" /> -> <img src="/shapes/triangle.png" alt="triangle" /> -> <img src="/shapes/cylinder.png" alt="cylinder" /> -> <input className="logicAssessment__answer" />
                        </div>
                    </div>
                    <div className="logicAssessment__challenge">
                        <div className="logicAssessment__clue">
                        <input className="logicAssessment__answer"/> -> <img src="/shapes/triangle.png" alt="triangle" /> -> <img src="/shapes/square.jpeg" alt="square" /> -> 12
                        </div>
                    </div>
                    <button className="logicAssessment__submit" onClick={onSubmit}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default LogicAssessment