import React from 'react';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import footerLogo from "../../assets/images/footer-logo.svg"
import "./Footer.scss"
import skype from "../../assets/images/skype.svg"
import mIcon from "../../assets/images/m-icon.svg"
import linkedin from "../../assets/images/linkedin.svg"

function Copyright() {
  return (
    <Typography className='copywrite' variant="body2" align="center">
      {'Copyright © '}
      <a className='nav-link' href="https://www.codetrack.dev/">
        CodeTrack.dev
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer(props) {
  return (
    <div className="footer">
      <div className='container'>
        <div className='nav-logo-wrapper'>
          <a href="/" className='footer-logo'>
            <img src={footerLogo} alt="logo" />
          </a>
          <ul className='nav-items'>
            <li className='nav-link'><Link to="/howtouse">How To Use</Link></li>
          </ul>
        </div>
        {/* <ul className='social-icons'>
          <li className='social-link'><Link to=""><img src={skype} alt='social icon' /></Link></li>
          <li className='social-link'><Link to=""><img src={mIcon} alt='social icon' /></Link></li>
          <li className='social-link'><Link to=""><img src={linkedin} alt='social icon' /></Link></li>
        </ul> */}
      </div>
      <Copyright />
    </div>
  );
}

export default Footer;