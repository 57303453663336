import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, Button, TextField } from '@material-ui/core';
import cssIntroChallenges from '../../problemSets/cssIntroChallenges.json';
import './CssChallengeSubmission.scss';

interface CssChallengeSubmissionProps {
    loggedIn: boolean;
}

const CssChallengeSubmission: React.FC<CssChallengeSubmissionProps> = ({ loggedIn }) => {
    const challengeSlug = window.location.pathname.split('/')[2];
    const challengeData = cssIntroChallenges.find(challenge => challenge.slug === challengeSlug);

    const [challengeUrl, setChallengeUrl] = React.useState('');
    const [userId, setUserId] = React.useState('');
    const [useScss, setUseScss] = React.useState(false);
    const [useBem, setUseBem] = React.useState(false);
    const [eliminateDuplication, setEliminateDuplication] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        switch (e.currentTarget.id) {
            case "useScss":
                setUseScss(!useScss);
                break;
            case "useBem":
                setUseBem(!useBem);
                break;
            case "eliminateDuplication":
                setEliminateDuplication(!eliminateDuplication);
                break;
        }
    }

    useEffect(() => {
        if (loggedIn) {
            const requestOptions = {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
            };

            fetch(`${process.env.REACT_APP_API}/cssChallenges/challengeCompleted/${challengeSlug}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.challenge_url) {
                        setChallengeUrl(data.challenge_url);
                        setSubmitted(true);
                    }
                    setUserId(data.user_id);
                }).catch(err => {
                    console.log(err)
                })
        }
    }, [])

    const submitSolution = (e: React.MouseEvent<HTMLButtonElement>) => {
        setErrorMessage('');

        if (!validateSolutionUrl(challengeUrl)) {
            setErrorMessage('Invalid URL')
            return;
        }

        setLoading(true);

        const api = process.env.REACT_APP_API;

        const requestOptions = {
            method: 'POST',
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId, challengeSlug, challengeUrl, challengeName: challengeData?.name, challengeId: challengeData?.id })
        }

        fetch(`${api}/cssChallenges`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'error') {
                    throw (data.message)
                }
                setLoading(false);
                setSubmitted(true);
                setChallengeUrl(data.challenge_url);
            })
            .catch(err => {
                setLoading(false);
                console.log(err)
            });
    }

    const validateSolutionUrl = (url: string) => {
        let regexp = /https:\/\/codepen.io\/[0-9a-zA-Z\_\-]*\/pen\/[0-9a-zA-Z]*/g;
        if (regexp.test(url)) {
            setChallengeUrl(url);
            return true;
        } else {
            return false;
        }
    }

    const readyToSubmit = (useScss && useBem && eliminateDuplication && challengeUrl) || submitted ? false : true;

    return (
        <div className="cssChallengeSubmission">
            {!loggedIn && <p>Please <Link to='/login'>log in</Link> to submit your solution.</p>}
            {loggedIn && <>
                <h3>Submission Checklist:</h3>
                {submitted && <p>Thank you for submitting your solution!</p>}
                {!submitted && <>
                    <Checkbox
                        checked={useBem}
                        color="primary"
                        onClick={(e) => handleClick(e)}
                        id="useBem"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <label htmlFor="vehicle1"> I followed <a href="https://getbem.com/" target="_blank">BEM</a> conventions</label>
                    <br />
                    <Checkbox
                        checked={useScss}
                        color="primary"
                        onClick={(e) => handleClick(e)}
                        id="useScss"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />        <label htmlFor="vehicle1"> I utilized <a href="https://sass-lang.com/guide/" target="_blank">SCSS</a></label>
                    <br />
                    <Checkbox
                        checked={eliminateDuplication}
                        color="primary"
                        onClick={(e) => handleClick(e)}
                        id="eliminateDuplication"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <label htmlFor="vehicle1">I eliminated all unneccessay CSS</label>
                </>}
                <br />

                <TextField
                    variant="outlined"
                    placeholder="https://codepen.io/username/pen/mdjdXKZ"
                    value={challengeUrl}
                    onChange={(e) => setChallengeUrl(e.target.value)}
                    fullWidth
                    label={errorMessage && errorMessage}
                    error={!!errorMessage}
                    margin="normal"
                />
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={(e) => submitSolution(e)}
                    disabled={readyToSubmit}
                >
                    {submitted ? 'Update' : 'Submit'}
                </Button>
            </>}
        </div>
    )
}

export default CssChallengeSubmission;