import React, {useState, useEffect} from 'react';


import LoadingButton from '@mui/lab/LoadingButton';
import {  
    TextField, 
    Grid
}  from "@mui/material";
    
import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';

import CodewarsInstructions from '../codewarsInstructions/CodewarsInstructions';

import './CodewarsSettings.scss';

/*
   Notes:
    - This component is used to add a Codewars account to a user's profile.
    - The user will enter their Codewars username and click "Add Account".
    - The username will be sent to the backend to verify the account exists.
    - If the account exists, the user will see a success message.
    - The user will be shown a video on how to add a webhook to their codewars acct
    - If the account does not exist, the user will see an error message.
    - If the account is already connected, the user will see the account details.
    - The user can update the account by clicking "Update".
    - The user will be warned that this will require changes on Codewars site
    
    - Codewars score access needs to be changed to this new table
    - A data migration needs to be accomplished
    - Add action at Shadowbox in top right for 'Help Connecting Codewars'
*/

const ConnectedCodewarsAcct = ({codewarsData}) => {

    
    return (
        <div className="connected-codewars-acct">
            <div className="connected-codewars-acct__info">
                <div className="connected-codewars-acct__label">
                    <span>Username:</span> {codewarsData.username}
                </div>
                <div className="connected-codewars-acct__label">
                    <span>Webhook Token:</span> {codewarsData.webhook_token}
                </div>
            </div>
            <div className="deleteAcct__submit">
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    // onClick={() => setShowDeleteModal(true)}
                >
                    Update
                </LoadingButton>
            </div>
        </div>
    )
}

const CodewarsSettings = ({userId}) => {
    const [codewarsData, setCodewarsData] = useState('');
    const [userName, setUserName] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/codewars/check-data/${userId}`)
        .then((response) => response.json())
        .then((data) => {
            if(data.username){
                setCodewarsData(data);
            }
        }).catch((error) => {
            console.error(error);
        });
    }, []);


    // const handleChange = (event) => {
    //     setUserName(event.target.value.trim());
    // }

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     setLoading(true);
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //           'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             codewarsUsername: userName,
    //             userId: userId
    //         })
    //       };
          
    //       fetch(`${process.env.REACT_APP_API}/codewars/${userName}`, requestOptions)
    //         .then((response) => response.json())
    //         .then((data) => {
    //           setLoading(false);
    //           setCodewarsData(data);
    //         });
    // }

    return (
        <ShadowBox title="Codewars Settings">
            {codewarsData && <ConnectedCodewarsAcct codewarsData={codewarsData}/>}
            {!codewarsData && <>
                <p>Add your Codewars account.</p>
                <CodewarsInstructions userId={userId} codewarsData={codewarsData} setCodewarsData={setCodewarsData} />
                {/* <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            id="codewars"
                            label="Codewars username"
                            name="codewars"
                            value={userName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                </Grid> */}
                {/* <div className="acctInfo__submit">
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        Add Account
                    </LoadingButton>
                </div> */}
            </>}
        </ShadowBox>
    )
}

export default CodewarsSettings