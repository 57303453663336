// Layout Components
import PageLayout from "../layoutComponents/pageLayout/PageLayout";
import TwoColumns from "../layoutComponents/twoColumns/TwoColumns";

// Features
import ArticleList from "../components/articleList/ArticleList";
import HowToConnectPlatforms from "../features/howToConnectPlatforms";
const HowToUse = () => {
    return (
        <PageLayout>
            <TwoColumns left={<HowToConnectPlatforms/>} right={<ArticleList/>} rightSticky={true}/>
        </PageLayout>
    )
}

export default HowToUse;