export const fetchUserBadges = async (userId: number) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/badges/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching user badges:', error)
    throw error
  }
}
