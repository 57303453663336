// Layout Components
import PageLayout from '../layoutComponents/pageLayout/PageLayout';

// Features
import CreateAcctForm from '../features/createAcctForm';

// Types
import { OrgConfig } from '../shared/types/orgConfig';

type CreateAcctProps = {
    orgConfig: OrgConfig
    subdomain: string
    setLoggedIn: (loggedIn: boolean) => void
}

const CreateAccount = ({ orgConfig = {}, subdomain,  setLoggedIn}: CreateAcctProps) => {
    return (
        <PageLayout>
            <CreateAcctForm orgConfig={orgConfig} subdomain={subdomain} setLoggedIn={setLoggedIn} />
        </PageLayout>
    )
}

export default CreateAccount;