import React, {useEffect, useState} from "react";

import './App.scss';

import Routes from "./Routes";
import Footer from "./layout/footer/Footer";



const host = window.location.host;
const arr = host.split('.');
const subdomain = arr.length > 1 && arr[0] !== 'www' ? arr[0] : 'codetrack';

let pursuitMenu = {
  "logo" : {
    "src": "/pursuit_logo.png"
  },
 "menuItems" : [ 
      {
        "text" : "What Is CodeTrack?",
        "url" : "/howToUse"
      },
      {
        "text" : "Articles",
        "url" : "/articles"
      },
      {
        "text" : "BattingCage",
        "url" : "/battingcage"
      },
      {
        "text" : "Log In", 
        "url" : "/login", 
        "loggedIn" : false
      },
      {
        "text" : "Create Account", 
        "url" : "/createAccount", 
        "loggedIn" : false
      },
      {
        "text" : "Videos", 
        "url" : "/videos",
        "loggedIn" : true
      },
      {
        "text" : "Profile", 
        "url" : "/profile", 
        "loggedIn" : true
      },
      {
        "text" : "Account Settings",
        "url" : "/account-settings",
        "loggedIn" : true
      },
      {
        "text" : "Log Out", 
        "url" : "/logout", 
        "loggedIn" : true
      }
 ]
}

let codepathMenu = {
  "logo" : {
    "src": "/codepath_logo.jpeg"
},
 "menuItems" : [ 
      {
        "text" : "What Is CodeTrack?",
        "url" : "/howToUse"
      },
      {
        "text" : "Log In", 
        "url" : "/login", 
        "loggedIn" : false
      },
      {
        "text" : "Create Account", 
        "url" : "/createAccount", 
        "loggedIn" : false
      },
      {
        "text" : "Profile", 
        "url" : "/profile", 
        "loggedIn" : true
      },
      {
        "text" : "Log Out", 
        "url" : "/logout", 
        "loggedIn" : true
      }
 ]
}

let marcylabMenu = {
  "logo" : {
    "text": "The Marcy Lab School"
},
 "menuItems" : [ 
      {
        "text" : "What Is CodeTrack?",
        "url" : "/howToUse"
      },
      {
        "text" : "Log In", 
        "url" : "/login", 
        "loggedIn" : false
      },
      {
        "text" : "Create Account", 
        "url" : "/createAccount", 
        "loggedIn" : false
      },
      {
        "text" : "Profile", 
        "url" : "/profile", 
        "loggedIn" : true
      },
      {
        "text" : "Log Out", 
        "url" : "/logout", 
        "loggedIn" : true
      }
 ]
}

let rootNav = {
  "logo" : {
    "src": "/codetrack_logo.png"
},
"menuItems" : [ 
    // {
    //   "text" : "Bootcamps",
    //   "url" : "/learnMore",
    //   "loggedIn" : false
    // },
    // {
    //   "text" : "Universities",
    //   "url" : "/learnMore",
    //   "loggedIn" : false
    // },
    {
      "text" : "What Is CodeTrack?",
      "url" : "/howToUse"
    }, 
    {
      "text" : "Articles",
      "url" : "/articles"
    },
    {
      "text" : "Videos", 
      "url" : "/videos",
      "loggedIn" : true
    },
    {
      "text" : "BattingCage",
      "url" : "/battingcage"
    },
    {
      "text" : "Log in", 
      "url" : "/login",
      "loggedIn" : false,
      "class":"btn-transparent-outline"
    },
    {
      "text" : "Sign up for free", 
      "url" : "/createAccount",
      "loggedIn" : false,
      "class":"btn-purple"

    },
    {
      "text" : "Profile", 
      "url" : "/profile", 
      "loggedIn" : true
    },
    {
      "text" : "Account Settings",
      "url" : "/account-settings",
      "loggedIn" : true
    },
    {
      "text" : "Log Out", 
      "url" : "/logout", 
      "loggedIn" : true
    }]
}

let navData;

switch(subdomain){
  case 'pursuit':
    navData = pursuitMenu;
    break;
  case 'codepath':
    navData = codepathMenu;
    break;
  case 'marcylab':
    navData = marcylabMenu;
    break;
  default:
    navData = rootNav;
}

function App() {

  const [orgConfig, setOrgConfig] = useState(require(`./orgConfig/${subdomain}.json`));
  const [theme, setTheme] = useState(orgConfig.orgName || 'codetrack');
  const [expandMenu, setExpandMenu] = useState(false);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const copyOrgConfig = {...orgConfig};
    if(subdomain !== 'codetrack'){
      const url = `${process.env.REACT_APP_API}/organizations/organization_profile/${subdomain}`
      fetch(url)
        .then(res => res.json())
        .then((result) => {
          copyOrgConfig.userType = result.user_type;
          copyOrgConfig.orgName = result.name;
          copyOrgConfig.emailSuffix = result.email_suffix;
          copyOrgConfig.reqOrgEmail = result.email_required;
          setOrgConfig(copyOrgConfig);
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      )
    } else {
      copyOrgConfig.userType  = 'Coder';
      copyOrgConfig.orgName = 'codetrack';
      setOrgConfig(copyOrgConfig);
      setLoading(false);  
    }
  }, [])

  useEffect(() => {
    setTheme(orgConfig.orgName || 'codetrack');
  }, [orgConfig]);


  useEffect(() => {
    const root = document.documentElement;

    root?.style.setProperty(
      "--overflow-var",
      expandMenu ? "hidden" : "visible"
    );

  }, [expandMenu]);

  const collapseMenu = e => {
    e.stopPropagation()
    setExpandMenu(false)
  }

  return (
    <>
      {loading && <div className="codeTrack__loading">Loading...</div>}
      {!loading && <div data-theme={theme} className={expandMenu ? "App expanded_menu" : "App"}>
        <Routes subdomain={subdomain} orgConfig={orgConfig} navData={navData} expandMenu={expandMenu} setExpandMenu={setExpandMenu} />
      </div>}
      
      <Footer/>
    </>
  );
}

export default App;

