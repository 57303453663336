// Layout Components
import PageLayout from "../layoutComponents/pageLayout/PageLayout"

// Features
import SecurityCodeForm from "../features/securityCodeForm";

const SecurityCodeGenerator = () => {
    return (
        <PageLayout>
            <SecurityCodeForm />
        </PageLayout>
    )
}

export default SecurityCodeGenerator;