import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';

import './SelectorCard.scss';

interface SelectorCardProps {
    text: string;
    action: (isSelected: boolean, text: string) => void;
    cohorts: string[];
}

function SelectorCard({ text, action, cohorts }: SelectorCardProps) {
    const [showToast, setShowToast] = React.useState(false);
    const vertical = 'top';
    const horizontal = 'center';

    const cardClicked = () => {
        if (cohorts.length === 5 && !cohorts.includes(text)) {
            setShowToast(true);
        }
        action(!cohorts.includes(text), text);
    }

    return (
        <div className="selectorCard" onClick={() => cardClicked()}>
            <Snackbar
                open={showToast}
                anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={2000}
                onClose={() => setShowToast(false)}
                message="Cohort selection is limited to 5 cohorts."
            />
            <Checkbox
                checked={cohorts.includes(text)}
                inputProps={{
                    'aria-label': 'primary checkbox'
                }}
                data-testid="checkbox"
            />
            {text}
        </div>
    );
}

export default SelectorCard;