import React,{useRef} from 'react';
import isEqual from 'lodash/isEqual';
import AceEditor from "react-ace";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

import './CurrentProblem.scss';

const CurrentProblem = ({problemId, strikes, setStrikes, score, setScore, battingHistory, setBattingHistory, currentProblem}) => {
    const [currentTestIndex, setCurrentTestIndex] = React.useState(0);
    const [currentCode, setCurrentCode] = React.useState("");
    const inputEl = useRef(null);

    React.useEffect(()=> {
      inputEl.current.editor.focus();
      inputEl.current.editor.setValue("");
    }, [problemId]);

    //This is a work-around for the submit via enter and the ACE IDE
    React.useEffect(()=> { 
      keyPressed(currentCode);
    }, [currentCode]);

    const generateBattingHistory = (actualOutput, status, code, testResults)=> {

      let passedCases = testResults && testResults.filter(test=>test).length;
      let currentBatObj = Object.assign({}, battingHistory[battingHistory.length-1]);

      let newInProgressBatterHistory = [
        ...currentBatObj.atBatHistory,
        {
          input: currentProblem.tests[currentTestIndex].input,
          output: currentProblem.tests[currentTestIndex].output,
          actualOutput: actualOutput,
          status: status, 
          code: code
        }
      ];
      currentBatObj.atBatHistory = newInProgressBatterHistory;
      currentBatObj.totalTestsPassed = passedCases || 0;
      
      if(testResults){
        currentBatObj.testResults = testResults.map((result, index)=>{
          const shouldShowNextIndex = (testResults[currentTestIndex] && (index === testResults.indexOf(false)));
          return ({
            shouldDisplay: !!(index === currentTestIndex || shouldShowNextIndex || currentBatObj.testResults[index].shouldDisplay),
            currentlyPassing: result
          })
        })
      }

      if(strikes === 2 && status !== "Ball"){
        currentBatObj.result = "Out";
      }
      if(passedCases === currentBatObj.totalTests){
        currentBatObj.result = "Score";
      }

      setBattingHistory([...battingHistory.slice(0, -1), currentBatObj]);
    }
  
    const keyPressed = (code) => {
      if(!code) return;
      let status;
      try{
        let testResults = generateTestResults(code);
        let input = currentProblem.tests[currentTestIndex].input;
        let actualOutput = eval(`(${JSON.stringify(input)})${code}`);

        if(testResults[currentTestIndex]){
          status = "Hit";
          if(testResults.every(result => result)){
            setScore(score + 1);
            setStrikes(0);
            setCurrentTestIndex(0);
          } else {
            setCurrentTestIndex(testResults.indexOf(false));
          }
        } else {
          status = "Strike";
          setStrikes(strikes + 1);
        }
        generateBattingHistory(actualOutput, status, code, testResults);

      } catch (err){ 
        console.log(err); 

        let actualOutput = err.name + ': ' + err.message;
        let status = "Ball";

        if(err.name === "TypeError"){
          status = "Strike";
          setStrikes(strikes + 1);
        }

        generateBattingHistory(actualOutput, status);
      }
    }
  
    const generateTestResults = (code) => {
      let tests =  currentProblem.tests;
  
      let testResults = tests.map((test) => {
          let result = eval(`(${JSON.stringify(test.input)})${code}`);
          return isEqual(test.output , result);
      });
  
      return testResults;
    }

  const problemInput = () => {
    return JSON.stringify(currentProblem.tests[currentTestIndex].input, null, 2);   
  }
  
  return (
    <div className="currentProblem">
      <div className="currentProblem__input">{currentProblem && currentProblem.tests && problemInput()}</div>
      <AceEditor
        ref={inputEl}
        maxLines={1}
        placeholder="Take your swing!"
        mode="javascript"
        theme="monokai"
        value={""}
        name="code_input"
        showGutter={false}
        fontSize={20}
        width="100%"
        highlightActiveLine={false}
        enableSnippets={false}
        editorProps={{ $blockScrolling: true }}
        showPrintMargin={false}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: false,
          tabSize: 2,
          useWorker: false
        }}
        defaultValue=""
        commands={[{ 
          name: 'submit_code',
          bindKey: {win: 'Return', mac: 'Return'},
          exec: (editor)=>{
            setCurrentCode(editor.getValue());
          }
        }]}
      />
      <div className="currentProblem__output"> <FontAwesomeIcon icon={faArrowRight}/> {currentProblem && currentProblem.tests && JSON.stringify(currentProblem.tests[currentTestIndex].output)}</div>
    </div>
  )
}

export default CurrentProblem;
