import React from 'react';

import './UploadImageToS3WithReactS3.scss';

window.Buffer = window.Buffer || require("buffer").Buffer;

const UploadImageToS3WithReactS3 = ({selectedFile, setSelectedFile}) => {

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    return (
        <div className="uploadImageToS3WithReactS3">
            <div className="uploadImageToS3WithReactS3__title">Select a Screen Shot</div>
            <input type="file" onChange={handleFileInput}/>
        </div>
    )
}

export default UploadImageToS3WithReactS3;