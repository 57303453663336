import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import './SingleBadge.scss';
import badges from '../../data/badges.json';

interface Badge {
    url?: string;
    message?: string;
}

interface BadgeCollection {
    [key: string]: Badge;
}

function SingleBadge({ badge = {} as Badge, score }: { badge?: Badge; score: number }) {
   
   if(!badge.url){
       const badgeCollection: BadgeCollection = badges;
       switch (true) {
            case score >= 850:
                badge = badgeCollection['850PointsTotal'];
                break;
            case score >= 300:
                badge = badgeCollection['300PointsTotal'];
                break;
            case score >= 50:
                badge = badgeCollection['50PointsTotal'];
                break;
            case score >= 10:
                badge = badgeCollection['10PointsTotal'];
                break;
        }
    }
    
    return (
        <div className="singleBadge">
            { badge.url && 
              <Tooltip title={badge.message || ''} arrow placement="top">
                <img src={badge.url} alt="" /> 
              </Tooltip>
            }
        </div>
    );
}

export default SingleBadge;