import React, { useEffect, useState } from 'react';

import cssIntroChallenges from '../../problemSets/cssIntroChallenges.json';

import './CssChallenge.scss';

interface Challenge {
  id: number;
  name: string;
  slug: string;
  difficulty: string;
  subtitle: string;
  referenceImage: string;
  description: string;
}

const CssChallenge = () => {
  const challengeSlug = window.location.pathname.split('/')[2];

  const [challenge, setChallenge] = useState<Challenge | null>(null);

  useEffect(() => {
    const challengeData = cssIntroChallenges.find(
      (challenge) => challenge.slug === challengeSlug
    );
    setChallenge(challengeData || null);
  }, [challengeSlug]);

  if (!challenge) {
    return null;
  }

  return (
    <div className="cssChallenge">
      <div className="cssChallenge__headline">
        HTML & CSS Challenge: {challenge.name}{' '}
      </div>
      <div className="cssChallenge__headlineDescription">
        {challenge.subtitle}
      </div>

      <img
        src={challenge.referenceImage}
        alt="Norstrom Thematic Card"
        className="cssChallenge__image"
      />

      <div dangerouslySetInnerHTML={{ __html: challenge.description }} />
    </div>
  );
};

export default CssChallenge;
