import React, {useState, useEffect} from 'react';

import CodeChallenge from '../../components/codeChallenge/CodeChallenge';
import { TableContainer, Table, TableBody, TableCell, TableHead, Paper } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"


import './ProblemTable.scss';

const ProblemTable = ({problemSet, refreshProblems, setToastMessage}) => {

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    }

    const onDragEnd= async(result)=>{
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedProblems = reorder(
            problemSet.problems,
            result.source.index,
            result.destination.index
        )

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({problemSetId:problemSet.id, reorderedProblems})
        };
        let response = await fetch(`${process.env.REACT_APP_API}/problemsets/reorder`, requestOptions);
        let data = await response.json();

        if(response.ok){
            refreshProblems();
        } else {
            if(data.message){
                setToastMessage(data.message);
            } else {
                setToastMessage("An error occurred while adding problem");
            }
        }
        
    }

    const headCells = [
        // {
        //     id: 'select',
        //     numeric: false,
        //     disablePadding: true,
        //     label: '',
        // },
        {
            id: 'order_num',
            numeric: false,
            disablePadding: false,
            label: 'Order'
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: false,
            label: 'Title'
        },
        {
            id: 'difficulty',
            numeric: false,
            disablePadding: false,
            label: 'Difficulty',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Actions',
            disableSort: true
        }
    ];

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    {headCells.map((headCell) => {
                        return <TableCell align="center">{headCell.label}</TableCell>
                    })}
                </TableHead>
                <TableBody component={DroppableComponent(onDragEnd)}>
                    {problemSet.problems.map((problem, index) => {
                        return (
                            <CodeChallenge key={problem.title + index} index={index} problem={problem} problemSetId={problemSet.id} refreshProblems={refreshProblems} />
                        )
                    })}    
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const DroppableComponent = (onDragEnd) => (props) =>
{
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'1'} direction="vertical">
                {(provided) => {
                    return (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </TableBody>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
}

export default ProblemTable;