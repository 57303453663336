import { API_BASE_URL } from '../../../config'
import { LoginFormData } from '../types'

export const login = async (loginData: LoginFormData) => {
  const { email, password } = loginData

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  }

  try {
    const response = await fetch(`${API_BASE_URL}/auth/login`, requestOptions)
    const result = await response.json()

    if (result.error) {
      throw new Error(result.error)
    }

    return {
      success: true,
      message: "Login successful!",
      accessToken: result.accessToken,
      organization: result.organization
    }
  } catch (error: unknown) {
    return {
      success: false,
      message: error instanceof Error ? error.message : "An error occurred while logging in."
    }
  }
}
