import React, { useState, useEffect } from 'react'
import { 
  Box, 
  Checkbox, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  TextField, 
  Button, 
  Snackbar,
  useMediaQuery,
  useTheme,
  Grid
} from '@mui/material';
import ShadowBox from '../../../layoutComponents/shadowBox/ShadowBox'
import { saveGithubUsername, getStudentGithubRepos } from '../../../services/githubService.tsx'

import { sampleRepos } from './sampleRepos'

const GithubRepos = ({playerInfo}) => {
  const [repos, setRepos] = useState(sampleRepos)
  const [githubUsername, setGithubUsername] = useState('');
  const [showToast, setShowToast] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchRepoCompletion = async (githubUsername) => {
      try {
        const reposCompletionData = await getStudentGithubRepos(githubUsername);

        const reposWithCompletionData = repos.map((repo) => {
          const repoCompletionData = reposCompletionData.find(repoCompletion => {
            return repoCompletion.repo_name === repo.name
          })
          
          return {...repo,...repoCompletionData}
        })

        setRepos(reposWithCompletionData)
      } catch (error) {
        console.error('Error fetching GitHub repos:', error)
      }
    }

    playerInfo.github_username && fetchRepoCompletion(playerInfo.github_username);
    setGithubUsername(playerInfo.github_username)
  }, [playerInfo])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await saveGithubUsername(playerInfo.id, githubUsername)
      playerInfo.github_username = githubUsername
      setShowToast('success');
    } catch (error) {
        setShowToast('fail');
        console.error('Error updating GitHub username:', error)
    }
  }

  return (
    <>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        message={showToast ==='success'? 'GitHub username successfully added!' : 'Error updating GitHub username'}
    />
    <ShadowBox title="GitHub Repositories">
      {!githubUsername ? (
        <Box component="form" onSubmit={(e) => e.preventDefault()} sx={{ mt: 2 }}>
          <Typography variant="body1" gutterBottom>
            Connect your GitHub account to view CodeTrack repositories.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', mt: 2 }}>
            <TextField
              label="GitHub Username"
              variant="outlined"
              inputProps={{ maxLength: 39 }}
              sx={{ flexGrow: 3, mr: isSmallScreen ? 0 : 2, mb: isSmallScreen ? 2 : 0, width: '100%' }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ flexGrow: 1, height: '40px', width: isSmallScreen ? '100%' : 'auto' }}
              onClick={handleSubmit}
            >
              Connect
            </Button>
          </Box>
        </Box>
      ) : (
        <List>
        {repos.map((repo) => (
            <ListItem key={repo.id} divider sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} alignItems="flex-start" width="100%">
                    <ListItemText
                    primary={
                        <a href={repo.url} target="_blank" rel="noopener noreferrer">
                        {repo.name}
                        </a>
                    }
                    secondary={repo.description}
                    sx={{ flexGrow: 1 }}
                    />
                    {!isSmallScreen && (
                        <Box display="flex" alignItems="center" gap='10px'>
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    checked={repo.is_forked ? true : false}
                                    color="primary"
                                    disabled={!repo.is_forked}
                                    disableRipple
                                    sx={{
                                        '&:hover': { boxShadow: 'none' },
                                        '&.Mui-checked': { cursor: 'default' }
                                    }}
                                />
                                <Typography variant="body2">Forked</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <Checkbox
                                checked={repo.pr_opened ? true : false}
                                color="primary"
                                disabled={!repo.pr_opened}
                                disableRipple
                                sx={{
                                    '&:hover': { boxShadow: 'none' },
                                    '&.Mui-checked': { cursor: 'default' }
                                }}
                                />
                                <Typography variant="body2">PR Opened</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                checked={repo.ai_reviewed ? true : false}
                                color="primary"
                                disabled={!repo.ai_reviewed}
                                disableRipple
                                sx={{
                                    '&:hover': { boxShadow: 'none' },
                                    '&.Mui-checked': { cursor: 'default' }
                                }}
                                />
                                <Typography variant="body2">AI Reviewed</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                checked={repo.staff_reviewed ? true : false}
                                color="primary"
                                disabled={!repo.staff_reviewed}
                                disableRipple
                                sx={{
                                    '&:hover': { boxShadow: 'none' },
                                    '&.Mui-checked': { cursor: 'default' }
                                }}
                                />
                                <Typography variant="body2">Staff Reviewed</Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
                {isSmallScreen && (
                    <Box display="flex" flexDirection="column" alignItems="flex-start" mt={1} width="100%">
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                        <Box display="flex" alignItems="center">              
                            <Checkbox
                                checked={repo.is_forked}
                                color="primary"
                                disabled={!repo.is_forked}
                                disableRipple
                                sx={{
                                    '&:hover': { boxShadow: 'none' },
                                    '&.Mui-checked': { cursor: 'default' }
                                }}
                            />
                            <Typography variant="body2">Forked</Typography>
                        </Box>
                        </Grid>
                        <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                            checked={repo.pr_opened}
                            color="primary"
                            disabled={!repo.pr_opened}
                            disableRipple
                            sx={{
                                '&:hover': { boxShadow: 'none' },
                                '&.Mui-checked': { cursor: 'default' }
                            }}
                            />
                            <Typography variant="body2">PR Opened</Typography>
                        </Box>
                        </Grid>
                        <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                            checked={repo.ai_reviewed}
                            color="primary"
                            disabled={!repo.ai_reviewed}
                            disableRipple
                            sx={{
                                '&:hover': { boxShadow: 'none' },
                                '&.Mui-checked': { cursor: 'default' }
                            }}
                            />
                            <Typography variant="body2">AI Reviewed</Typography>
                        </Box>
                        </Grid>
                        <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                            checked={repo.staff_reviewed}
                            color="primary"
                            disabled={!repo.staff_reviewed}
                            disableRipple
                            sx={{
                                '&:hover': { boxShadow: 'none' },
                                '&.Mui-checked': { cursor: 'default' }
                            }}
                            />
                            <Typography variant="body2">Staff Reviewed</Typography>
                        </Box>
                        </Grid>
                    </Grid>
                    </Box>
                )}
          </ListItem>
        ))}
      </List>)}
    </ShadowBox>
    </>
  )
}

export default GithubRepos