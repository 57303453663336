import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import {  
    TextField, 
    Grid
}  from "@mui/material";

import FileCopyIcon from '@mui/icons-material/FileCopy';

// implement stepper form
// https://mui.com/material-ui/react-stepper/


const CodewarsInstructions = ({userId, codewarsData, setCodewarsData}) => {

    const [showToast, setShowToast] = React.useState(false);
    const [userName, setUserName] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const copyToClipboard = () => {
        const codewarsWebhook = 'https://codewars-tracker-be.herokuapp.com/codewars';
        navigator.clipboard.writeText(codewarsWebhook);

        setShowToast(true);
    }

    const handleChange = (event) => {
        setUserName(event.target.value.trim());
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                codewarsUsername: userName,
                userId: userId
            })
          };
          
          fetch(`${process.env.REACT_APP_API}/codewars/${userName}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              setLoading(false);
              setCodewarsData(data);
            });
    }


    return (
        <div>
            <p>
                Codewars is a platform for practicing algorithmic problems in several different languages. It
                offers a great way to practice both the fundamentals and advanced aspects of any common language.
            </p>
            {/* <p>The easiest way to connect your account is to watch this video and follow along.</p>

            <div style={{"position": "relative", "padding-bottom": "56.25%", "height": "0"}}><iframe src="https://www.loom.com/embed/03fbe7d6b6b44dc7a1bb05138c77fd31?sid=c44e8f9b-bc16-49bb-b7ed-0875c621342d" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{"position": "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}}></iframe></div> */}

            {/* <p>
                <b>Alternately, follow these steps:</b>
            </p> */}

            <ol>
                <li>Log in to your <a href="https://www.codewars.com/" target="_blank"><b>Codewars account</b></a>.</li>
                <li>Navigate to <a href="https://www.codewars.com/users/edit" target="_blank"><b>My Account</b></a></li>
                <li>Grab your username from the Username field and paste it below:</li>
                <Grid container spacing={2}  style={{"margin": "10px 0"}}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            id="codewars"
                            label="Codewars username"
                            name="codewars"
                            value={userName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            loading={loading}
                            fullWidth
                            style={{"height": "100%"}}
                        >
                            Add Account
                        </LoadingButton>
                    </Grid>
                </Grid>
                <li>Scroll down to the section that is titled: <b>Webhooks</b>.</li>
                <img style={{ "width": "100%" }} src="/codewars_webhook_screenshot.png" />
                <li>Copy and paste the following URL into the 'Payload url' field: 
                    <div style={{"fontWeight" : "600", "padding" : "8px"}}>
                        https://codewars-tracker-be.herokuapp.com/codewars
                        <FileCopyIcon className="copy-text-icon" onClick={copyToClipboard}/>
                    </div>
                </li>
                <li>Copy and paste your unique webhook id in the Secret field: 
                    <div style={{"fontWeight" : "600", "padding" : "8px"}}>
                        {codewarsData.token}
                        <FileCopyIcon className="copy-text-icon" onClick={copyToClipboard}/>
                    </div>
                </li>
                <li>Click on the 'Update' button at the bottom of the page.</li>
                <p>
                    Looks like you're good to go! Your score has been added to CodeTrack.
                    Whenever you complete a Codewars problem for the first time, points will automatically 
                    be added to your CodeTrack profile.
                </p>
                
            </ol>
            <p>Bonus: want to solve a Codewars problem with me? <a href="https://www.youtube.com/watch?v=ZLlGo0kAilA&ab_channel=JordanManley" target="_blank" ><b>Watch this video.</b></a></p>
            <p>Still having issues connecting Codewars to CodeTrack? Schedule a <a href="https://calendly.com/codetrack/codetrack-introductory-demo" target="_blank" ><b>quick call</b></a> to get help connecting your account.</p>
        </div>
    )
}

export default CodewarsInstructions;