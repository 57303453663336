import React from 'react';
import { Button } from '@material-ui/core';

const GameIntro = ({setGameMode, removeCookie, handleClose}) => {

    const restartWithTutorial = () => {
        removeCookie('playedTutorial');
        setGameMode('intro');
        handleClose();
    }

    return (
        <>
            <p>
            Practice your knowledge of JavaScript functions in this quick and simple
            game. Pick it up whenever you want and play until you get 3 outs.
            </p>
            <p>
            Each problem set has 9 problems, selected at random. You can play the same 
            modes multiple times and get different problems.
            </p>
            <p>
            Each problem has one or more inputs or 'pitches'. You must write 
            the code that works for all pitches. The only catch is you'll only see one pitch at a time.
            A run is scored when your code works for all pitches. 
            </p> 
            <p>
            With each problem, you'll have three chances to get it right. 
            If you get it wrong, you'll get a strike. Three strikes and you're out!
            </p>
            <div className="button-container">
                <Button  
                    variant="contained"
                    onClick={restartWithTutorial}> 
                    Restart with tutorial
                </Button>
                <Button  
                    variant="contained"
                    color="primary" 
                    onClick={handleClose}> 
                    Got it!
                </Button>
            </div>
        </>
    )
}

export default GameIntro;
