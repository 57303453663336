export const getDailyStandings = async (subdomain: string) => {
  try {
    const url = `${process.env.REACT_APP_API}/dailyStandings/${subdomain}`
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching daily standings:', error)
    throw error
  }
}
