// Layout Components
import PageLayout from '../layoutComponents/pageLayout/PageLayout';

// Features
import ResetPasswordForm from '../features/resetPasswordForm/index';

const ResetPassword = () => {
  return (
    <PageLayout>
      <ResetPasswordForm />
    </PageLayout>
  )
}

export default ResetPassword;