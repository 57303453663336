import React from 'react';
import { Typography, TextField, Button, Box, Link } from '@mui/material';

import { useResetPassword } from './hooks/useResetPassword';

const ResetPasswordForm: React.FC = () => {

  const { formData, handleSubmit, handleChange, errors } = useResetPassword();
  console.log(formData);
  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, margin: 'auto',  bgcolor: "white", padding: 2, borderRadius: 2 }}>
        <Typography variant="h5" component="h1" align="center" gutterBottom>
        Reset Password
        </Typography>
        <p style={{"fontWeight" : "600"}}>
            You will need a security code. If you don't have one, request one <Link href='/generateCode'><span style={{"fontSize": "20px" }} >here</span></Link>.
        </p>
      <TextField
        margin="normal"
        required
        fullWidth
        onChange={handleChange}
        value={formData.email}
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        error={!!errors.email}
        helperText={errors.email}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        onChange={handleChange}
        value={formData.securityCode}

        id="securityCode"
        label="Security Code"
        name="securityCode"
        error={!!errors.securityCode}
        helperText={errors.securityCode}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        onChange={handleChange}
        value={formData.newPassword}
        id="newPassword"
        label="New Password"
        name="newPassword"
        type="password"
        autoComplete="new-password"
        error={!!errors.newPassword}
        helperText={errors.newPassword}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        onChange={handleChange}
        value={formData.confirmNewPassword}
        id="confirmPassword"
        label="Confirm New Password"
        name="confirmNewPassword"
        type="password"
        autoComplete="new-password"
        error={!!errors.confirmNewPassword}
        helperText={errors.confirmNewPassword}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Reset Password
      </Button>
    </Box>
  );
};

export default ResetPasswordForm;
