import React, { useState, useEffect, Suspense } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import Snackbar from "@mui/material/Snackbar";

import StickyNavbar from '../layout/stickyNavbar/StickyNavbar';
import TopScoresCollection from '../components/topScoresCollection/TopScoresCollection';
import PlayerCardCollection from '../components/playerCardCollection/PlayerCardCollection';
import ScoreBoard from '../components/scoreBoard/ScoreBoard';

const StaffCardCollection = React.lazy(() =>
  import("../components/staffCardCollection/StaffCardCollection")
);

function Home({
  subdomain = "codetrack",
  loggedIn,
  setLoggedIn,
  userType,
  orgConfig = {},
}) {
  let history = useHistory();

  const location = useLocation();

  const parsed = queryString.parse(location.search);

  if (parsed && parsed.accessToken) {
    localStorage.setItem("accessToken", location.search.split("=")[1]);
    setLoggedIn(true);
    history.push("/");
  }

  const [fellows, setFellows] = useState([]);
  const [fellowCount, setFellowCount] = useState(0);
  const [cohorts, setCohorts] = useState([]);
  const [employedFellows, setEmployedFellows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toastNote, setToastNote] = useState(location?.state?.toastMessage);
  const [showToast, setShowToast] = useState(!!location?.state?.toastMessage);

  const fetchUsersOfOrganizationWithPagination = async (
    organization,
    page = 1
  ) => {
    const url = new URL(`${process.env.REACT_APP_API}/users`);
    url.search += location.search;
    url.searchParams.set("organization", organization);
    url.searchParams.set("isPaginationActive", "true");
    //remove when finished with testing url updates
    const response = await fetch(url.href);
    const result = await response.json();
    if (result.status === "success") {
      setFellows([...result.users]);
      setFellowCount(result.count);
    } else {
      setToastNote("An error ocurred in fetching users.");
      setShowToast(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    //any time the query params change rerender Home
    fetchUsersOfOrganizationWithPagination(subdomain);
    if (parsed.cohort) {
      let cohorts = parsed.cohort.split(",");
      setCohorts(cohorts);
    }
  }, [location, subdomain]);

  return (
    <div className="home">
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(!showToast)}
        message={toastNote}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <ScoreBoard subdomain={subdomain} />
      {subdomain !== "codetrack" && (
        <StickyNavbar
          subdomain={subdomain}
          orgConfig={orgConfig}
          cohorts={cohorts}
          setCohorts={setCohorts}
        />
      )}
      <TopScoresCollection
        loading={loading}
        location={location}
        fellows={fellows}
        subdomain={subdomain}
      />
      <PlayerCardCollection
        userType={userType}
        cohorts={cohorts}
        fellows={fellows}
        fellowCount={fellowCount}
        setFellows={setFellows}
        setEmployedFellows={setEmployedFellows}
        loading={loading}
        subdomain={subdomain}
      />

      <Suspense fallback={<div>Loading...</div>}>
        <StaffCardCollection cohorts={cohorts} subdomain={subdomain} />
      </Suspense>
    </div>
  );
}

export default Home;
