import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from "react-router-dom";

import OrgSettings from '../../components/orgSettings/OrgSettings';
import PlanSummary from '../../components/planSummary/PlanSummary';
import InviteMembers from '../../components/inviteMembers/InviteMembers';
import InviteList from '../../components/inviteList/InviteList';
import MemberList from '../../components/memberList/MemberList';
import Snackbar from '@mui/material/Snackbar';

import './OrgAccountManagement.scss';

function OrgAccountManagement({subdomain, orgConfig}) {

    let history = useHistory();

    const [invites, setInvites] = useState([]);
    const [adminInfo, setAdminInfo] = useState({});
    const [toastMessage, setToastMessage] = useState("");

    const jwtToken =  localStorage.getItem('accessToken');

    useEffect(() => {
        const requestOptions = {
            headers: {'Authorization': `Bearer ${jwtToken}`},
        };

        fetch(`${process.env.REACT_APP_API}/users/profile`, requestOptions)
            .then(response => response.json())
            .then(data => {

                if(data.error){
                    console.error("User profile data retrieval error: ", data.error);
                    history.push('/login');
                } else {
                    setAdminInfo(data);
                }
            }).catch(err => {
                console.error(err);
                history.push('/login');
            })

        refreshInvites();
    }, [])

    const refreshInvites = async ()=> {
        let url = `${process.env.REACT_APP_API}/accountmanagement/${subdomain || "codetrack"}/invites`;

        const requestOptions = {
            headers: {'Authorization': `Bearer ${jwtToken}`},
        };

        let response = await fetch(url, requestOptions);
        let data = await response.json();

        if (response.ok) {
            setInvites(data);
        } else {
            console.error(data);
            history.push("/");
        }
    }

    return (
        <div className="orgAccountManagement">
            <Snackbar
                open={!!toastMessage}
                autoHideDuration={2000}
                onClose={() => setToastMessage("")}
                message={toastMessage}
                anchorOrigin={{'vertical': 'top', 'horizontal': 'center'}  }
            />
            <h1>Account Management</h1>
            <OrgSettings subdomain={subdomain} setToastMessage={setToastMessage} />
            <PlanSummary orgConfig={orgConfig} />
            <InviteMembers subdomain={subdomain} refreshInvites={refreshInvites} setToastMessage={setToastMessage} jwtToken={jwtToken} orgConfig={orgConfig}/>
            <InviteList subdomain={subdomain} refreshInvites={refreshInvites} setToastMessage={setToastMessage} invites={invites} jwtToken={jwtToken}/>           
            <MemberList subdomain={subdomain} jwtToken={jwtToken} setToastMessage={setToastMessage} />
            <MemberList subdomain={subdomain} jwtToken={jwtToken} role="Staff" />
        </div>
    );
}

export default OrgAccountManagement;