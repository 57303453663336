import React, { useState, useEffect } from "react";
import { uploadFile } from "react-s3";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import UploadImageToS3WithReactS3 from "../uploadImageToS3WithReactS3/UploadImageToS3WithReactS3";
// import {AiOutlineReload } from 'react-icons/ai';
import AutorenewIcon from "@mui/icons-material/Autorenew";

import "./PortfolioProjectForm.scss";

window.Buffer = window.Buffer || require("buffer").Buffer;

const PortfolioProjectForm = ({ userId }) => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [prodURL, setProdURL] = useState("");
  const [githubFrontend, setGithubFrontend] = useState("");
  const [githubBackend, setGithubBackend] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [successfulUpdate, setSuccessfulUpdate] = useState("");
  const [portfolioData, setPortfolioData] = useState({});
  const [portfolioExists, setPortfolioExists] = useState(false);
  const api = `${process.env.REACT_APP_API}/portfolios/${userId}`;

  useEffect(() => {
    fetch(api)
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          setTitle(result.title);
          setSubtitle(result.subtitle);
          setProdURL(result.prod_url);
          setGithubFrontend(result.github_frontend);
          setGithubBackend(result.github_backend);
          setProjectDescription(
            replaceBrWithNewLine(result.portfolio_description)
          );
          setPortfolioExists(true);
        }
      });
  }, []);

  const handleChange = (e) => {
    const field = e.target.name;

    switch (field) {
      case "title":
        setTitle(e.target.value);
        break;
      case "subtitle":
        setSubtitle(e.target.value);
        break;
      case "prodURL":
        setProdURL(e.target.value);
        break;
      case "githubFrontend":
        setGithubFrontend(e.target.value);
        break;
      case "githubBackend":
        setGithubBackend(e.target.value);
        break;
      case "projectDescription":
        setProjectDescription(e.target.value);
        break;
    }
  };

  const processDescription = (text) => {
    // Remove any HTML tags
    text = text.replace(/<[^>]*>/g, "");

    // Remove any use of the eval function
    text = text.replace(/eval\((.*)\)/g, "");

    // Convert new line characters to <br> tags
    text = text.replace(/\n/g, "<br>");

    return text;
  };

  //write a function to replace <br> with new line characters
  const replaceBrWithNewLine = (text) => {
    return text.replace(/<br>/g, "\n");
  };

  const handleSubmit = async () => {
    const method = portfolioExists ? "PUT" : "POST";

    if (imageURL) {
      handleSubmitWithImageUpload(method);
      return;
    }

    setLoading(true);

    const formattedDescription = processDescription(projectDescription);

    let url = `${process.env.REACT_APP_API}`;
    // prepare reqParams
    const requestOptions = {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        title,
        subtitle,
        prodURL,
        githubFrontend,
        githubBackend,
        formattedDescription,
      }),
    };

    fetch(url + "/portfolios/" + userId, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        window.scrollTo(0, 0);
        setSuccessfulUpdate(true);
        setShowSnackbar(true);
      })
      .catch((err) => {
        setLoading(false);
        //setSuccessfulUpdate(false);
        // setShowSnackbar(true);
      });
  };

  const handleSubmitWithImageUpload = async (method) => {
    //validate image type
    const imageType = imageURL.type;
    if (imageType !== "image/jpeg" && imageType !== "image/png") {
      setSnackbarMessage("Please upload a valid image file");
      setShowSnackbar(true);
      return;
    }

    //validate image size
    const imageSize = imageURL.size;
    if (imageSize > 1000000) {
      setSnackbarMessage("Please upload an image file less than 1MB");
      setShowSnackbar(true);
      return;
    }

    setLoading(true);

    let url = `${process.env.REACT_APP_API}`;

    const formattedDescription = processDescription(projectDescription);

    const response = await fetch(`${url}/awscredentials`);
    const awsCredentials = await response.json();
    // fetch
    uploadFile(imageURL, awsCredentials).then((data) => {
      // set get new image url from data
      let awsImageURL = data.location;

      // prepare reqParams
      const requestOptions = {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          title,
          subtitle,
          prodURL,
          githubFrontend,
          githubBackend,
          awsImageURL,
          formattedDescription,
        }),
      };

      fetch(url + "/portfolios/" + userId, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          window.scrollTo(0, 0);
          setSuccessfulUpdate(true);
          setSnackbarMessage(successElement);
          setShowSnackbar(true);
        })
        .catch((err) => {
          setLoading(false);
          //setSuccessfulUpdate(false);
          // setShowSnackbar(true);
        });
    });
  };

  // const action = portfolioExists ? 'updating' : 'adding';
  // setErrorMessage('An error occurred while {`${action}`} your portfolio — please try again later.');
  const errorElement = <Alert severity='error'>{snackbarMessage}</Alert>;
  const successElement = <Alert>Portfolio was updated successfully!</Alert>;

  return (
    <div className='portfolioProjectForm'>
      <Snackbar
        open={showSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
      >
        {successfulUpdate ? successElement : errorElement}
      </Snackbar>

      <div className='portfolioProjectForm__inputs'>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <TextField
              className='portfolioProjectForm__input'
              fullWidth
              id='outlined-basic'
              label='Project Name'
              variant='outlined'
              value={title}
              name='title'
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              className='portfolioProjectForm__input'
              fullWidth
              id='outlined-basic'
              label='Subtitle'
              variant='outlined'
              value={subtitle}
              name='subtitle'
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              className='portfolioProjectForm__input'
              id='outlined-basic'
              label='Production URL'
              variant='outlined'
              value={prodURL}
              name='prodURL'
              onChange={(e) => handleChange(e)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              className='portfolioProjectForm__input'
              id='outlined-multiline-static'
              label='Github Front End'
              value={githubFrontend}
              name='githubFrontend'
              onChange={(e) => handleChange(e)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              className='portfolioProjectForm__input'
              id='outlined-multiline-static'
              label='Github Back End'
              value={githubBackend}
              name='githubBackend'
              onChange={(e) => handleChange(e)}
              fullWidth
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <div className='portfolioProjectForm__imageUpload'>
              <UploadImageToS3WithReactS3
                selectedFile={imageURL}
                setSelectedFile={setImageURL}
                />
            </div>
          </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            id='outlined-multiline-static'
            className='portfolioProjectForm__input'
            label='Project Description'
            multiline
            rows={4}
            value={projectDescription}
            fullWidth
            name='projectDescription'
            onChange={(e) => handleChange(e)}
            />
        </Grid>
      </Grid>
      </div>
      <div className='portfolioProjectForm__submit'>
        <Button
          variant='contained'
          // disabled={!anyChanges}
          onClick={handleSubmit}
          endIcon={
            loading && (
              <AutorenewIcon className='portfolioProjectForm__submitLoader-spinning' />
            )
          }
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default PortfolioProjectForm;
