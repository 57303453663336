export const generateSecurityCode = async (email: string) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API}/securityCodeGenerator`, requestOptions);
        const data = await response.json();
    } catch (err) {
        console.error(err);
    }
};


