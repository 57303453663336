import React from 'react';

import './BattingHistoryList.scss';

const BattingHistoryList = ({children}) => {
    return (
      <div className="battingHistoryList">
        <img src="/battingcage_1.png" alt="batting cage" />
        {children}
      </div>
    )
  }

export default BattingHistoryList;
