
// given a max number, create an array of n random numbers between 0 and max - 1
const genrateRandomArray = (max, n = 9) => {
    let randomArray = [];

    while(randomArray.length < n){

        let randomIndex = Math.floor(Math.random() * max);

        if(!randomArray.includes(randomIndex)){
            randomArray.push(randomIndex);
        }
    }
    
    return randomArray;
}

//select 9 unique items at random from an array
const randomSelection = (array, selectionSize=9) => {
   
    if(array.length < selectionSize){
        selectionSize = array.length;
    }
    
    let selection = [];
    let randomIndex = 0;
    let randomItem = null;
    let i = 0;
   
    // generate a random array of indexes
    let randomArray = genrateRandomArray(array.length, selectionSize);

    // insert the items at the random indexes into the selection array
    while(i < selectionSize){
        randomIndex = randomArray[i];
        randomItem = array[randomIndex];
        selection.push(randomItem);
        i++;
    }

    return selection;
}

export default randomSelection;