import React from 'react';
import { Link } from "react-router-dom";

import './ArticleList.scss';

interface ArticleListProps {
    dropShadow?: boolean;
}

const ArticleList: React.FC<ArticleListProps> = ({ dropShadow }) => {

    const styleObj: React.CSSProperties = {
        boxShadow: "0px 5px 15px rgba(160, 160, 160, 0.58)"
    };

    return (
        <div className="articleList" style={dropShadow ? styleObj : undefined}>
            <div className="articleList__title"> 
                <Link to="/articles">
                    <span className="purple-circle"></span>CodeTrack Articles
                </Link>
            </div>
            <div className="articleList__list">
                <img src="/images/woman_coding.jpg" alt="Woman coding" />
                <div className="article">
                    <div className="article__number">1</div>
                    <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@jordan_92311/landing-your-first-job-as-a-software-developer-f8424035f1de">
                        <div className="article__title">
                            Landing Your First Job as a Software Developer
                        </div>
                    </a>
                </div>
                <div className="article">
                    <div className="article__number">2</div>
                    <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@jordan_92311/leading-with-your-work-one-amazing-application-6378f9f3596b">
                        <div className="article__title">
                            Leading With Your Work: One Amazing Application
                        </div>
                    </a>
                </div>
                <div className="article">
                    <div className="article__number">3</div>
                    <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@jordan_92311/building-a-quick-email-feature-to-nudge-users-based-on-a-sql-query-81a06204d1e6">
                        <div className="article__title">
                            Building a Quick Email Feature to Nudge Users Based on a SQL Query
                        </div>
                    </a>
                </div>
                {/* <div className="article">
                    <div className="article__number">4</div>
                    <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@jordan_92311/syncing-badges-when-data-goes-haywire-895acbcfa91c">
                        <div className="article__title">
                        Syncing Badges When Data Goes Haywire
                        </div>
                    </a>
                </div> */}
            </div>
        </div>
    );
};

export default ArticleList;