import { useEffect, useState } from 'react';
import { fetchUserBadges } from '../services/badgesService';

interface Badges {
  id: number
  user_id: number
  pointstotal10: boolean
  pointstotal50: boolean
  pointstotal300: boolean
  pointstotal850: boolean
  fourweeks25: boolean
  eightweeks25: boolean
  sixteenweeks25: boolean
  total1week50: boolean
  total1week150: boolean
  total1week300: boolean
}

export function useFetchBadges(userId: number) {
    const [badges, setBadges] = useState<Badges | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchBadges = async () => {
            try {
                const data = await fetchUserBadges(userId);
                setBadges(data);
                setIsLoading(false);
            } catch (error) {
                setError(error instanceof Error ? error : new Error('An unknown error occurred'));
                setIsLoading(false);
            }
        };

        fetchBadges();
    }, [userId]);

    return { badges, isLoading, error };
}