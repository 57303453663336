
import React, { useState } from 'react';
import { TextField, Typography, Button, Box, Link } from '@mui/material';

import  useSecurityCode  from './hooks/useSecurityCode';

const GenerateSecurityCodeForm = () => {
  
  const { errors, formData, success, handleSubmit, handleChange } = useSecurityCode();

  if(success)
    return (
      <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, margin: 'auto',  bgcolor: "white", padding: 2, borderRadius: 2 }}>

         <p>
             We've emailed you a new security code!
         </p>
         <Link className="securityCodeGenerator__redirect" href='/' sx={{ marginRight: '15px' }}>Home</Link>
         <Link className="securityCodeGenerator__redirect" href='/resetPassword'>Reset Password</Link>
     </Box>
    )

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, margin: 'auto',  bgcolor: "white", padding: 2, borderRadius: 2 }}>
      <Typography variant="h5" component="h1" align='center' gutterBottom>
        Generate Security Code
      </Typography>
      <p>
          You can reset your password with a unique code. 
          To get a code, please submit your email below.
      </p>
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
        margin="normal"
        error={!!errors.email}
        helperText={errors.email}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default GenerateSecurityCodeForm;
