import SelectorCard from '../selectorCard/SelectorCard';

import './ClassSelector.scss';

interface OrgConfig {
    classes: string[];
}

interface ClassSelectorProps {
    cohorts: string[];
    toggleCohort: (add: boolean, option: string) => void;
    orgConfig?: OrgConfig;
}

function ClassSelector({ cohorts, toggleCohort, orgConfig }: ClassSelectorProps) {
    let options: string[] = orgConfig?.classes || [];

    return (
      <div className="classSelector">
        <div className="classSelector__container">
          {options.map((option, index) => (
            <SelectorCard
              action={(add: boolean) => toggleCohort(add, option)}
              text={option}
              key={index}
              cohorts={cohorts}
            />
          ))}
        </div>
      </div>
    );
}

export default ClassSelector;