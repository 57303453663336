import React from 'react';
import BattingHistoryItem from '../battingHistoryItem/BattingHistoryItem';
import Popover from 'react-bootstrap/Popover';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faBaseballBall, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './AtBatContainer.scss';


const AtBatContainer = ({atBat, playedTutorial, gameMode}) => {
    let eachAtBat = [];

    if(atBat.atBatHistory.length > 0){
        eachAtBat = atBat.atBatHistory.map((bat, index) => {
            return (
                <BattingHistoryItem
                    key={index}
                    input={bat.input} 
                    code={bat.code} 
                    output={bat.actualOutput}
                    status={bat.status}
                />
            )
        })
    } else if(playedTutorial) {
        eachAtBat.push(
            <h3 key={-1}>Batter Up</h3>
        )
    }

    let currentStrikes = atBat.atBatHistory.filter(bat=>bat.status === "Strike").length;

    const surgeCases = atBat.problemId === 1 && eachAtBat.length > 0  && atBat.totalTestsPassed < 3 && !playedTutorial && gameMode === "intro";
    
    const hintPopover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Batting Coach says:</Popover.Title>
            <Popover.Content>
                {atBat.hint}
            </Popover.Content>
        </Popover>
    );

    const casesPopover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">{atBat.totalTests} Pitches</Popover.Title>
            <Popover.Content>
            {
                atBat.tests.map((test, index) => {
                    if(atBat?.testResults[index]?.shouldDisplay || atBat.totalTestsPassed === atBat.totalTests){
                        return(
                            <div className="center" key={index}>
                                <div className="test-case">
                                    {JSON.stringify(test.input)} <FontAwesomeIcon icon={faArrowRight}/> {JSON.stringify(test.output)}
                                </div>
                                { atBat.testResults[index].currentlyPassing &&
                                    <span className="right" style={{"color": "green"}}><FontAwesomeIcon icon={faCheck} /></span>
                                }
                                { !atBat.testResults[index].currentlyPassing &&
                                    <span className="right" style={{"color": "red"}}><FontAwesomeIcon icon={faTimes} /></span>
                                }
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} className="blurred">Lorem Bolorem <FontAwesomeIcon icon={faArrowRight}/>Boleah Jobolea</div>
                        )
                    }
                })
            }
            </Popover.Content>
        </Popover>
    );

    return (
        <div className={"atBatContainer " + atBat.result.toLowerCase()}>
            
            <div className={"atBatContainer__problemInfo " + atBat.result.toLowerCase()}>
                <div className="atBatContainer__strikes">
                    <span className="semi-bold ">Strikes:</span> {currentStrikes}
                </div> 
                <OverlayTrigger trigger="click" placement="top" rootClose overlay={casesPopover}>
                    <div className={"atBatContainer__cases" + (surgeCases ? " atBatContainer__cases-surging": "")}>
                        <span className="semi-bold pointer">Cases Passed: </span> 
                        {atBat.totalTestsPassed}/{atBat.totalTests}
                    </div>
                </OverlayTrigger>
                <div>
                { (gameMode == "easy" || currentStrikes > 0) && atBat.hint && atBat.result == "batting" &&
                    <OverlayTrigger trigger="click" placement="top" rootClose overlay={hintPopover}>
                        <div className={"atBatContainer__baseball-hint semi-bold right pointer" + (currentStrikes === 1 ? " atBatContainer__baseball-hint-surging" : "")}>
                            <FontAwesomeIcon icon={faBaseballBall}  size="lg"/>
                        </div>
                    </OverlayTrigger>
                }
                </div>
            </div>
            {atBat.problemId === 0 && eachAtBat.length < 1 && !playedTutorial &&
                <div className="atBatContainer__prompt">
                    Enter code below so that the input equals the desired output.
                    <br />
                    Ie. Given an input of <code>4</code> what code would you add to output <code>5</code>?
                    <br />
                    <code>4 + 1</code> <FontAwesomeIcon icon={faArrowRight}/> <code>5</code> <br />
                    Go ahead and input <code>+ 1</code> below to solve the problem.
                </div>
            }

            {atBat.problemId === 1 && eachAtBat.length < 2 && atBat.totalTestsPassed === 0 && !playedTutorial &&
                <div className="atBatContainer__prompt">
                    Great job! 
                    <br />
                    Each 'at bat' has 1 or more cases. Your code must work for all cases
                    <br />
                    Solve the case below (<code>5</code> <FontAwesomeIcon icon={faArrowRight}/> <code>10</code>), by adding the code <code> + 5</code>
                </div>
            }
                
            {atBat.problemId === 2 && eachAtBat.length < 3 && atBat.atBatHistory.length === 0 && !playedTutorial &&
                <div className="atBatContainer__prompt batting-history-item">
                    Amazing! 
                    <br />
                    When your code doesn't work, one of two things will happen. 
                    <br />
                    On most errors, you'll get a ball. Let's try that now. 
                    <br />
                    Enter <code>blah</code>
                </div>
            }

            {atBat.problemId === 3 && eachAtBat.length < 4 && atBat.totalTestsPassed === 0 && !playedTutorial &&
                <div className="atBatContainer__prompt batting-history-item">
                    You're doing great! 
                    <br />
                    I think you've got it from here. One more and you're on to the real game.
                    <br />
                    Just focus on passing the revealed pitches. Don't worry if you <br />
                    dont solve every case on the first swing. Batter Up!
                </div>
            }

            {eachAtBat}
            {atBat.problemId === 1 && eachAtBat.length > 0  && atBat.totalTestsPassed < 3 && !playedTutorial &&
                <div className="atBatContainer__prompt batting-history-item">
                    Nice. Almost there. 
                    <br />
                    Each 'at bat' has 1 or more cases. You can see the revealed cases by <b>clicking</b> 'Cases Passed' button above.
                    <br />
                    <code> + 5 </code> solves the first case, but what code would solve both cases:<br />
                    <code>5 <FontAwesomeIcon icon={faArrowRight}/> 10</code> and <code>100 <FontAwesomeIcon icon={faArrowRight}/> 200</code>?
                </div>
            }
            {atBat.problemId === 2 && atBat.atBatHistory.length === 1 && atBat.atBatHistory[0].status === "Ball" && !playedTutorial &&
                <div className="atBatContainer__prompt batting-history-item">
                    Cool. So that's a ball. You won't be penalized for most balls, but also, you<br />
                    won't be able to advance to the next problem. Now let's input some code that is valid<br />
                    <strong>but</strong> doesn't solve the problem. Try this: <code> + 24</code>
                </div>
            }
            {atBat.problemId === 2 && atBat.atBatHistory.length === 2 && atBat.atBatHistory[1].status === "Strike" && !playedTutorial &&
                <div className="atBatContainer__prompt batting-history-item">
                    Ok. That's a strike. On your first strike, you will get a clue by clicking on the <FontAwesomeIcon icon={faBaseballBall} /> <br />
                    above. When you get three strikes on the same problem, you will get an out and move onto the next problem.<br />
                    Now, lets solve this with the following code: <code>.trim()</code>
                </div>
            }                
        </div>
      )
}

export default AtBatContainer;