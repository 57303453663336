import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import './CodeTrackIntroVideo.scss';

const BottomContent = () => {
    const history = useHistory(); 

    const handleSignUpClick = () => {
        history.push('/createaccount'); 
    };

    return (
        <div className='codeTrackIntroVideo'>
            <div className='codeTrackIntroVideo__textContainer'>
                <h3>What is CodeTrack?</h3>
                <p>
                    This application is set up to help you track your progress and consistency in working with <br />
                    Codewars. Every time you complete a problem, an event will be emitted that updates your <br />
                    total points, your weekly points and your monthly points.
                </p>
                <h4>Watch this video to learn more.</h4>
            </div>
            <div className='codeTrackIntroVideo__videoPlaceholder'>
                <div className='codeTrackIntroVideo__videoPlaceholder__circleContainer'>
                    <img src="/Polygon 3.png" alt="Play Button" className='codeTrackIntroVideo__videoPlaceholder__playButton' />
                </div>
            </div>
            <div className='codeTrackIntroVideo__signup'>
                <Button className='btn-purple' onClick={handleSignUpClick}>
                    Sign Up
                </Button>
            </div>
        </div>
    );
};

export default BottomContent;
