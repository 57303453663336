import React, { useState, useEffect } from "react";

import LoadingCards from "../loadingCards/LoadingCards";
import PlayerCard from "../playerCard/PlayerCard";
import EmptyView from "../emptyView/EmptyView";

import { Link } from "react-router-dom";

import "./TopScoresCollection.scss";

function TopScoresCollection({ fellows, loading, subdomain, location }) {
  const [topUsers, setTopUsers] = useState([]);
  async function fetchTopUsers(organization) {
    const url = new URL(`${process.env.REACT_APP_API}/users`);
    const currentQuery = new URLSearchParams(location.search);
    url.searchParams.set("organization", organization);
    url.searchParams.set("isPaginationActive", "true");
    url.searchParams.set("limit", 3);
    url.searchParams.set("sortBy", "pointsThisWeek");
    if (currentQuery.has("cohort")) {
      url.searchParams.set("cohort", currentQuery.get("cohort"));
    }
    const response = await fetch(url.href);
    const result = await response.json();
    setTopUsers(result.users);
  }
  useEffect(() => {
    fetchTopUsers(subdomain);
  }, [subdomain, location]);

  useEffect(() => {
    //removed when testing is complete
    // if (fellows.length) {
    //   setTopUsers(
    //     [...fellows]
    //       .sort((a, b) => (a.points_this_week > b.points_this_week ? -1 : 1))
    //       .slice(0, 3)
    //   );
    // }
  }, [fellows]);

  return (
    <div className="topScoresCollection">
      <div className="topScoresCollection__header">
        <h1>This Week's Top Scores</h1>
      </div>
      <div className="topScoresCollection__container">
        {!topUsers.length && loading && <LoadingCards count={3} />}
        {topUsers.length > 0 && !loading && (
          <>
            {topUsers.map((userInfo, index) => {
              return (
                <Link
                  key={userInfo.name + index}
                  to={{
                    pathname: "/fellow/" + userInfo.id,
                    state: {
                      playerInfo: userInfo,
                    },
                  }}
                >
                  <PlayerCard playerInfo={userInfo} place={index} topScore />
                </Link>
              );
            })}
          </>
        )}
      </div>
      {topUsers.length < 1 && !loading && <EmptyView text="No Results" />}
    </div>
  );
}

export default TopScoresCollection;
