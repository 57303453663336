import React from 'react';

import './PortfolioDisplay.scss';

interface PortfolioData {
    title: string;
    subtitle: string;
    prod_url: string;
    github_backend: string;
    github_frontend: string;
    aws_image_url: string;
    portfolio_description: string;
}

interface PortfolioDisplayProps {
    portfolioData: PortfolioData;
    setShowEditForm: React.Dispatch<React.SetStateAction<boolean>>;
    showEditForm: boolean;
}

const PortfolioDisplay: React.FC<PortfolioDisplayProps> = ({portfolioData}) => {
  
    return (
      <>
          <div className="portfolio__titleContainer">
              <div className="portfolio__title">{portfolioData?.title}</div>
          </div>
          <div className="portfolio__subtitle">{portfolioData.subtitle}</div>
          <div className="portfolio__links">
              <div className="portfolio__link"><span>Production</span>: <a target='_blank' href={portfolioData.prod_url}>{portfolioData?.title}</a></div>
              <div className="portfolio__link"><span>GitHub</span>: <a target='_blank' href={portfolioData.github_backend}>Backend</a></div>
              <div className="portfolio__link"><span>GitHub</span>: <a target='_blank' href={portfolioData.github_frontend}>Frontend</a></div>
          </div>
          <img  className="portfolio__mainImg" src={portfolioData.aws_image_url} alt="placeholder"/>
          <div className="portfolio__description" dangerouslySetInnerHTML={{__html: portfolioData.portfolio_description}}></div>
          <div style={{"clear" : "both"}}></div>
      </>
    )
}

export default PortfolioDisplay