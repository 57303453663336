import {useState, useEffect} from 'react';

import { fetchLeetcodeStats } from '../services/leetcodeService';

export function useFetchLeetcode (id: number, leetcodeId: string){
    const [leetcodeData, setLeetcodeData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchLeetcodeData = async () => {
            try {
                const data = await fetchLeetcodeStats(id, leetcodeId);
                setLeetcodeData(data);
                setLoading(false);
            } catch (error) {
                setError(error instanceof Error ? error : new Error('An unknown error occurred'));
                setLoading(false);
            }
        };
        fetchLeetcodeData();
    }, [id, leetcodeId]);

    return {leetcodeData, loading, error};
}