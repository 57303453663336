import './TwoColumns.scss';

interface TwoColumnsProps {
    left: React.ReactNode,    
    right: React.ReactNode,
    rightSticky?: boolean,
    leftSticky?: boolean
}

const TwoColumns = ({ left, right, rightSticky, leftSticky }: TwoColumnsProps) => {
    
    const styleObj: React.CSSProperties = {
        height: "65vh",
        position: "sticky",
        top: "40px",
    };
    
    return (
        <div className="twoColumns">
            <div className="twoColumns__container">
                <div className="twoColumns__left" style={leftSticky ? styleObj : undefined}>
                    {left}
                </div>
                <div className="twoColumns__right" style={rightSticky ? styleObj : undefined}>
                    {right}
                </div>
            </div>
        </div>
    )
}

export default TwoColumns;