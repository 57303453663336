import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";

import Collapsible from 'react-collapsible';
// import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';

import ProblemTable from '../problemTable/ProblemTable';

import './ProblemSet.scss';

const ProblemSetHeader = ({title, completedCount, problemCount, id}) => {

    let history = useHistory();


    const editProblemSet = (e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(`/problemSets/${id}/edit`)
    }

    return (
        <div className="problemSetHeader">
            <span className="problemSetHeader__title">{title}</span>
            <span className="problemSetHeader__count"> Problems: {problemCount}</span>
            <Button  variant="contained" color="primary" onClick={(e)=> editProblemSet(e) } className="problemSetHeader__edit"> Edit Problem Set</Button>
            <Button  variant="contained" color="primary" onClick={(e)=> editProblemSet(e) } className="problemSetHeader__assign"> Assign Problem Set</Button>

        </div>
    )
}

// const CodeChallenge = ({problem}) => {

//     const {title, url, difficulty} = problem;

//     return (
//         <div className="codeChallenge">
            
//             <a href={url} target="_blank">
                
//                 <Checkbox
//                     color="primary"
//                     disabled
//                     inputProps={{ 'aria-label': 'primary checkbox' }}
//                 />
                
//                 <span className="codeChallenge__title">{title} </span>
//                 <span className="codeChallenge__difficulty">{difficulty}</span>

//             </a>
//         </div>
//     )
// }


function ProblemSet({problemSet}) {
    const {id, title, problem_count} = problemSet;

    const [problems, setProblems] = useState([]);

    
    useEffect(() => {
        refreshProblems();
    }, [])
    
    const refreshProblems = () => {
        fetch(`${process.env.REACT_APP_API}/problemsets/${id}`)
            .then(response => response.json())
            .then(data => {
                setProblems(data.problems);
            }).catch(error => {
                console.error(error);
            })
        }
    return (
        <Collapsible 
            trigger={<ProblemSetHeader title={title} problemCount={problem_count} id={id} />} 
            classParentString="challengeCollection"
            open={false}
            >
            <div className="challengesContainer">
                { problems.length ? (
                        <ProblemTable problemSet={{...problemSet, problems}} refreshProblems={refreshProblems} />
                 ) : (
                    <div style={{textAlign:"center"}}>No problems found</div>
                )}
            </div>
        </Collapsible>
    );
}

export default ProblemSet;