import React from "react";

import ShadowBox from "../../layoutComponents/shadowBox/ShadowBox";
import "./UserProfileCard.scss";

const UserProfileCard = ({ playerInfo }) => {
  const totalPoints = playerInfo?.honor + playerInfo?.leetcode_points + playerInfo?.free_code_camp_points;

  return (
    <ShadowBox className='userProfileCard' displayDescription="space-between">
        <div className='userProfileCard__name'>{playerInfo.name}</div>
        <div className='userProfileCard__info'>
          <div>
            {playerInfo.cohort && (
              <div className='userProfileCard__class'>
                <div className='userProfileCard__infoTitle'>Class: </div>
                <div className='userProfileCard__classNum'>
                  {playerInfo.cohort}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className='userProfileCard__infoTitle'>Total Score:</div>
            <div className='userProfileCard__totalScore'>{totalPoints}</div>
          </div>
          <div>
            <div className='userProfileCard__infoTitle'>Points:</div>
            <div className='userProfileCard__weekAndMonths'>
              <div className='userProfileCard__border'>
                <div className='userProfileCard__weekMonthNum'>
                  {playerInfo.points_this_week}
                </div>
                <div className='userProfileCard__weekAndMonthsTitle'>
                  This Week
                </div>
              </div>
              <div>
                <div className='userProfileCard__weekMonthNum'>
                  {playerInfo.points_this_month}
                </div>
                <div className='userProfileCard__weekAndMonthsTitle'>
                  This Month
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='userProfileCard__infoTitle'>Commits:</div>
            <div className='userProfileCard__weekAndMonths'>
              <div className='userProfileCard__border'>
                <div className='userProfileCard__weekMonthNum'>
                  {playerInfo.commits_this_week}
                </div>
                <div className='userProfileCard__weekAndMonthsTitle'>
                  This Week
                </div>
              </div>
              <div>
                <div className='userProfileCard__weekMonthNum'>
                  {playerInfo.commits_this_month}
                </div>
                <div className='userProfileCard__weekAndMonthsTitle'>
                  This Month
                </div>
              </div>
            </div>
          </div>
        </div>
    </ShadowBox>
)
};

export default UserProfileCard;
