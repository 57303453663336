import React from 'react';
import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';
import { useFetchLeetcode } from '../../hooks/useFetchLeetcode';

import './PointsBreakdown.scss';
function PointsBreakdown({playerInfo}) {
    const { id, leetcodeid, honor, leetcode_points } = playerInfo;
    const { leetcodeData, loading, error } = useFetchLeetcode(id, leetcodeid);

    const leetCodeProblems = {
        easy: leetcodeData?.easySolved || 0,
        medium: leetcodeData?.mediumSolved || 0,
        hard: leetcodeData?.hardSolved || 0
    }

    return (
        <ShadowBox className="points-breakdown" title="Point Breakdown" loading={loading}>
            {!loading && 
            <>
                <div className="points-breakdown__source">
                    <div className="points-breakdown__label">Codewars:</div>
                    <div>{honor}</div>
                </div>
                
                <div className="points-breakdown__source">
                    <div className="points-breakdown__label">Leetcode:</div>
                    <div>{leetcode_points}</div>
                </div>
                <div className="points-breakdown__leetcode-problems">
                    <table>
                        <tbody>
                            {Object.keys(leetCodeProblems).map(key => {
                                const formattedKey = key[0].toUpperCase() + key.slice(1)
                                return (
                                    <tr key={formattedKey}>
                                        <td>{formattedKey}:</td>
                                        <td>{leetCodeProblems[key]}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                
                <div className="points-breakdown__source">
                    <div className="points-breakdown__label">Total:</div>
                    <div>{honor + leetcode_points}</div>
                </div>
            </> }   
        </ShadowBox>
    )
}

export default PointsBreakdown;