const API_BASE_URL = process.env.REACT_APP_API;

export const saveGithubUsername = async (userId: string, githubUsername: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/users/${userId}/github`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ githubUsername }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error saving Github username:', error);
    throw error;
  }
}

export const getStudentGithubRepos = async (githubUsername: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/githubResources/${githubUsername}/repos`);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching Github repos:', error);
    throw error;
  }
}
