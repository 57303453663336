import React, {useState, useEffect} from 'react';

// import io from "socket.io-client";

import './ScoreBoard.scss'

function ScoreBoard({subdomain}) {

    subdomain = subdomain || 'codetrack';

    const [scoreboard, setScoreboard ] = useState({});
    // const [socket, setSocket] = useState(null);

    useEffect(() => {

        const url = `${process.env.REACT_APP_API}/scoreboard/${subdomain}`;
        fetch(url)
        .then(res => res.json())
        .then((result) => {
            setScoreboard(result);
        },
         
          (error) => {
            console.log(error);
          }
        )
    }, []);

    // useEffect(() => {
    //     const clientSocket = io('https://codewars-tracker-be.herokuapp.com/');

    //     clientSocket.on("codewars_points_scored", (updatedScores) => { 
    //         setScoreboard(updatedScores)
    //     });
    
    //     setSocket(clientSocket);
    // }, [])

    return (
        <div className="scoreBoard">
            <div className="scoreBoard__title">Score <br/> Board</div>
 
            <div className="scoreBoard__week">
                <div className="scoreBoard__subtitle"> Weekly </div>
                { subdomain != 'codetrack' && <>
                    <div>
                        <span>Fellows: </span> {scoreboard.fellowsThisWeek} 
                    </div>
                    <div>
                        <span>Staff: </span> {scoreboard.staffThisWeek} 
                    </div>
                </> }

                { subdomain == 'codetrack' &&
                    <div className='scoreBoard__singleScore'>
                        <span>Coders: </span> {scoreboard.fellowsThisWeek} 
                    </div>
                }
            </div>

            <div className="scoreBoard__month">
                <div className="scoreBoard__subtitle"> Monthly </div>
                { subdomain != 'codetrack' && <>
                    <div>
                        <span>Fellows: </span> {scoreboard.fellowsThisMonth} 
                    </div>
                    <div>
                        <span>Staff: </span> {scoreboard.staffThisMonth} 
                    </div>
                </> }
                { subdomain == 'codetrack' &&
                    <div className='scoreBoard__singleScore'>
                        <span>Coders: </span> {scoreboard.fellowsThisMonth}  
                    </div>
                }
            </div>

            <div className="scoreBoard__allTime">
                <div className="scoreBoard__subtitle"> All Time </div>
                { subdomain != 'codetrack' && <>
                    <div>
                        <span>Fellows: </span>{scoreboard.fellowsAllTime} 
                    </div>
                    <div>
                        <span>Staff: </span> {scoreboard.staffAllTime} 
                    </div>
                </> }
                { subdomain == 'codetrack' &&
                    <div className='scoreBoard__singleScore'>
                        <span>Coders: </span> {scoreboard.fellowsAllTime} 
                    </div>
                }
            </div>
        </div>
    );
}

export default ScoreBoard;