export const sampleRepos = [
    {
        id: 1,
        name: 'boxes_exercise',
        description: 'A React-based web application',
        url: 'https://github.com/werner33/boxes_exercise',
    },
    {
        id: 2,
        name: 'python-data-analysis',
        description: 'Data analysis scripts using Python',
        url: 'https://github.com/username/python-data-analysis',
    },
    {
        id: 3,
        name: 'nodejs-api',
        description: 'RESTful API built with Node.js and Express',
        url: 'https://github.com/username/nodejs-api',
    },
    {
        id: 4,
        name: 'machine-learning-models',
        description: 'Collection of machine learning models',
        url: 'https://github.com/username/machine-learning-models',
    },
    {
        id: 5,
        name: 'portfolio-website',
        description: 'Personal portfolio website',
        url: 'https://github.com/username/portfolio-website',
    },
];
