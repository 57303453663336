import React, { useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';

import Collapsible from 'react-collapsible';

import './CodewarsChallengesCollection.scss';

import grasshopper from '../../problemSets/grasshopper.json';
import grasshopperDebug from '../../problemSets/grasshopper-debug.json';
import strings1 from '../../problemSets/strings1.json';
import strings2 from '../../problemSets/strings2.json';
import strings3 from '../../problemSets/strings3.json';
import strings4 from '../../problemSets/strings4.json';
import recursion from '../../problemSets/recursion.json';
import linkedLists from '../../problemSets/linkedLists.json';
import cryptography from '../../problemSets/cryptography.json';
import higherOrderFunctions from '../../problemSets/higherOrderFunctions.json';
import arrays from '../../problemSets/arrays.json';
import matrixes from '../../problemSets/matrixes.json';
import controlFlow  from '../../problemSets/controlFlow.json';
import objects from '../../problemSets/objects.json';
import OOP from '../../problemSets/OOP.json';
import trees from '../../problemSets/trees.json';
import bubbleSort from '../../problemSets/bubbleSort.json';
import hashTables from '../../problemSets/hashTables';
import queues from '../../problemSets/queues.json';
import arraysIntro from '../../problemSets/arraysIntro.json';
import mathIntro from '../../problemSets/mathIntro.json';


const CodewarsChallengeCompleted = ({name, slug, rank, videoUrl}) => {

    let challengeLevel = Math.abs(rank.id);

    return (
        <div className="codewarsChallenge">
            <a href={"https://www.codewars.com/kata/" + slug } target="_blank">
                
                <Checkbox
                    checked
                    color="primary"
                    onClick={null}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                
                <span className="codewarsChallenge__title">{name} </span>
                <span className="codewarsChallenge__rank">Difficulty: {challengeLevel}</span>
            </a>
            {videoUrl && 
                <a className="codewarsChallenge__videoUrl" href={videoUrl} target="_blank">
                    <Button
                    variant="contained"
                    color="primary"
                    >
                        Video Lesson
                    </Button>
                </a>
            }
        </div>
    )
}

const CodewarsChallenge = ({name, slug, rank, videoUrl}) => {

    let challengeLevel = Math.abs(rank.id);

    return (
        <div className="codewarsChallenge">
            
            <a href={"https://www.codewars.com/kata/" + slug } target="_blank">
                
                <Checkbox
                    color="primary"
                    disabled
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                
                <span className="codewarsChallenge__title">{name} </span>
                <span className="codewarsChallenge__rank">{challengeLevel}</span>
                {videoUrl && 
                <a className="codewarsChallenge__videoUrl" href={videoUrl} target="_blank">
                    <Button
                    variant="contained"
                    color="primary"
                    >
                        Video Lesson
                    </Button>
                </a>
            }
            </a>
        </div>
    )
}

const ProblemSetHeader = ({title, completedCount, problemCount}) => {
    completedCount = completedCount || 0;
    return (
        <div className="problemSetHeader">
            <span className="problemSetHeader__title">{title}</span>
            <span className="problemSetHeader__count"> Complete: {completedCount}/{problemCount}</span>
        </div>
    )
}

const CodewarsChallengesCollection = ({problems, language, problemSet}) => {

    let selectedProblemSet;
    let completedCount = 0;

    switch(problemSet){
        case "grasshopper":
            selectedProblemSet = grasshopper;
            break;
        case "grasshopper-debug":
            selectedProblemSet = grasshopperDebug;
            break;
        case "strings1":
            selectedProblemSet = strings1;
            break;
        case "strings2":
            selectedProblemSet = strings2;
            break;
        case "arraysIntro":
            selectedProblemSet = arraysIntro;
            break;
        case "mathIntro":
            selectedProblemSet = mathIntro;
            break;
        case "higherOrderFunctions":
            selectedProblemSet = higherOrderFunctions;
            break;
        case "arrays":
            selectedProblemSet = arrays;
            break;
        case "matrixes":
            selectedProblemSet = matrixes;
            break;
        case "strings3":
            selectedProblemSet = strings3;
            break;
        case "strings4":
            selectedProblemSet = strings4;
            break;
        case "recursion":
            selectedProblemSet = recursion;
            break;
        case "cryptography":
            selectedProblemSet = cryptography;
            break;
        case "linkedLists":
            selectedProblemSet = linkedLists;
            break;
        case "controlFlow":
            selectedProblemSet = controlFlow;
            break;
        case "objects":
            selectedProblemSet = objects;
            break;
        case 'OOP':
            selectedProblemSet = OOP;
            break;
        case 'trees':
            selectedProblemSet = trees;
            break;
        case 'bubbleSort':
            selectedProblemSet = bubbleSort;
            break;
        case 'hashTables':
            selectedProblemSet = hashTables;
            break;
        case 'queues':
            selectedProblemSet = queues;
            break;
        default:
            break; 
    }

    let problemElements = selectedProblemSet.map(challenge => {

        let completed = problems && problems.filter(problem => problem.slug === challenge.slug).length == 1;
        if(completed) completedCount += 1;
    
        return (completed ? <CodewarsChallengeCompleted name={challenge.name} rank ={challenge.rank} slug={challenge.slug}  videoUrl={challenge.videoUrl} /> :
            <CodewarsChallenge name={challenge.name} rank ={challenge.rank} slug={challenge.slug} videoUrl={challenge.videoUrl} />)
    })

   
    return (
        <Collapsible 
            trigger={<ProblemSetHeader title={problemSet || "Starting Problems"} problemCount={selectedProblemSet.length} completedCount={completedCount} />} 
            classParentString="challengeCollection"
            open={completedCount != selectedProblemSet.length }
            >
            <div className="collectionOfCodewarsChallenges__container">
                {problemElements}
            </div>
        </Collapsible>
    );
}

export default CodewarsChallengesCollection;