import React from 'react'
import { TextField, Button, Box, Typography, Link, Alert } from '@mui/material'

import useLogin from './hooks/useLogin';

const LoginForm: React.FC<{ setLoggedIn: (loggedIn: boolean) => void }> = ({ setLoggedIn }) => {
  const {formData, handleChange, handleSubmit, errors} = useLogin(setLoggedIn);

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, margin: 'auto',  bgcolor: "white", padding: 2, borderRadius: 2 }}>
      <Typography variant="h5" component="h1" align="center" gutterBottom>
        Login
      </Typography>
      {errors?.general && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errors?.general}
        </Alert>
      )}
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        value={formData.email}
        autoComplete="email"
        autoFocus
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        value={formData.password}
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={handleChange}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </Button>
      <Box sx={{ textAlign: 'center', mt: '10px' }}>
        <Link href="/resetPassword" variant="body2">
          Reset Password
        </Link>
      </Box>
    </Box>
  )
}

export default LoginForm