import React from "react";

import {
    Tabs, 
    Tab, 
    Box, 
    Typography, 
    Snackbar
} from '@mui/material';

import FileCopyIcon from '@mui/icons-material/FileCopy';

import TrackProjectInstructions from '../../components/trackProjectInstructions/TrackProjectInstructions';

const styles = {
    "li" : {
        "marginBottom" : "10px"
    }
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function HowToUse() {
    const [value, setValue] = React.useState<number>(0);
    const [showToast, setShowToast] = React.useState<boolean>(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const copyToClipboard = () => {
        const codewarsWebhook = 'https://codewars-tracker-be.herokuapp.com/codewars';
        navigator.clipboard.writeText(codewarsWebhook);

        setShowToast(true);
    }

    return (
        <div className="article">
            <Snackbar
                open={showToast}
                autoHideDuration={2000}
                onClose={() => setShowToast(false)}
                message="Copied to clipboard"
            />
            <div className="article__headline" style={{"marginBottom" : "30px"}}>
                What is CodeTrack and How Does It Work?
            </div>
            <div className="article__headlineDescription">
                CodeTrack allows any developer to build a profile that represents their work, their skill and the type of work they
                are most interested in doing professionally. Your CodeTrack profile is a compiliation of work you do across several
                platforms like Github, Leetcode and Codewars.
            </div>
            <div className="article__headlineDescription">
                As your profile grows, it can augment or even supplant your resume as the best way to demonstrate your skill
                prior to an interview. It can also be used by employers to find the best candidates for their open positions.
            </div>
            <div className='content'>

                <h3 style={styles['li']}>Where To Start</h3>

                <p>
                    You'll want to start by connecting one or more platforms that you already use to CodeTrack. Follow the 
                    instructions below for Codewars, LeetCode or Github.
                </p>

                <p>It can be difficult to follow this process on a mobile device. You may want to wait until you are working at a desktop.</p>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Codewars"  />
                        <Tab label="Leetcode"  />
                        <Tab label="Github" />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <h3 style={styles['li']}>Codewars</h3>
                        <p>
                            Codewars is a platform for practicing algorithmic problems in several different languages. It
                            offers a great way to practice both the fundamentals and advanced aspects of any common language.
                        </p>
                        <p>The easiest way to connect your account is to watch this video and follow along.</p>

                        <div style={{"position": "relative", "paddingBottom": "56.25%", "height": "0"}}><iframe src="https://www.loom.com/embed/03fbe7d6b6b44dc7a1bb05138c77fd31?sid=c44e8f9b-bc16-49bb-b7ed-0875c621342d" frameBorder="0" allowFullScreen style={{"position": "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}}></iframe></div>

                        <p>
                            <b>Alternately, follow these steps:</b>
                        </p>

                        <ol>
                            <li  style={styles['li']}>Log into your Codewars account and navigate to <a href="https://www.codewars.com/users/edit" target="_blank" rel="noopener noreferrer"><b>My Account</b></a></li>
                            <li style={styles['li']}>Scroll down to the section that is titled: <b>Webhooks</b>.</li>
                            <img style={{ "width": "100%" }} src="/codewars_webhook_screenshot.png" alt="Codewars webhook screenshot" />
                            <li style={styles['li']}>Copy and paste the following URL into the 'Payload url' box: 
                                <div style={{"fontWeight" : "600", "padding" : "8px"}}>
                                    https://codewars-tracker-be.herokuapp.com/codewars
                                    <FileCopyIcon className="copy-text-icon" onClick={copyToClipboard}/>
                                </div>
                                </li>
                            <li style={styles['li']}>Click on the 'Update' button at the bottom of the page.</li>
                            <li style={styles['li']}>Open <a href='/account-settings' target="_blank" rel="noopener noreferrer"><b>your CodeTrack account settings</b></a> and add your Codewars name.</li>
                            <li style={styles['li']}>Click on the 'Update' button and you're good to go!</li>
                            <li style={styles['li']}>Now, whenever you complete a Codewars problem for the first time, points will automatically be added to your CodeTrack profile.</li>
                            
                        </ol>
                        <p>Bonus: want to solve a Codewars problem with me? <a href="https://www.youtube.com/watch?v=ZLlGo0kAilA&ab_channel=JordanManley" target="_blank" rel="noopener noreferrer"><b>Watch this video.</b></a></p>
                        <p>Still having issues connecting Codewars to CodeTrack? Schedule a <a href="https://calendly.com/codetrack/codetrack-introductory-demo" target="_blank" rel="noopener noreferrer"><b>quick call</b></a> to get help connecting your account.</p>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <h3 style={styles['li']}>Leetcode</h3>
                        <p>
                            Leetcode is a platform for practicing algorithmic problems in several different languages. Love it
                            or hate it, it is the 'gold standard' for algorithmic interviews.
                        </p>
                        <p>To connect your Leetcode account to CodeTrack, follow these steps:</p>
                        <ol>
                            <li style={styles['li']}>Log into your Leetcode account and navigate to <a href="https://leetcode.com/profile/account/" target="_blank" rel="noopener noreferrer"><b>your account</b></a></li>
                            <li style={styles['li']}>Copy your Leetcode ID from the top of your account information.</li>
                            <li style={styles['li']}>Open <a href='/account-settings' target="_blank" rel="noopener noreferrer"><b>your CodeTrack account settings</b></a> and add your Leetcode id.</li>
                            <li style={styles['li']}>Click on the 'Update' button and you're good to go!</li>
                        </ol>
                        <p>Still having issues connecting Leetcode to CodeTrack? Schedule a <a href="https://calendly.com/codetrack/codetrack-introductory-demo" target="_blank" rel="noopener noreferrer"><b>quick call</b></a> to get help connecting your account.</p>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <h3 style={styles['li']}>Github</h3>
                    <TrackProjectInstructions />
                    </TabPanel>
                </Box>
            </div>
        </div>
    );
}

export default HowToUse;