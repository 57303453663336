import TwoColumns from "../../layoutComponents/twoColumns/TwoColumns"
import { Button } from "@material-ui/core";

const HeroBlock=() => {
    
    const scrollToBottom = () => {
        const bottomSection = document.querySelector('.bottomContent');
        if (bottomSection) {
            bottomSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const leftContent = (
        <div className='guestLandingPage__leftContainer'>
            <h1>Welcome<br />to CodeTrack.</h1>
            <p>
                Codetrack syncs with all kinds of accounts<br /> like Codewars, Leetcode, and GitHub.
            </p>
            <Button className='btn-purple' onClick={scrollToBottom}>
                Learn More
            </Button>
        </div>
    );

    const rightContent = (
        <div className='guestLandingPage__rightContainer'>
            <img src="/HERO IMAGE.png" alt="Hero Image" />
        </div>
    );

    return (
        <div>
            <TwoColumns 
                    left={leftContent} 
                    right={rightContent} 
                    leftSticky={false} 
                    rightSticky={false} 
                />
        </div>
    )
}

export default HeroBlock