// Layout Components
import PageLayout from "../layoutComponents/pageLayout/PageLayout";
import TwoColumns from "../layoutComponents/twoColumns/TwoColumns";

// Components TODO: move to features and refactor
import CssChallenge from "../components/cssChallenge/CssChallenge";
import CssChallengeSubmission from "../components/cssChallengeSubmission/CssChallengeSubmission";
const CSSChallenge = ({loggedIn}: any) => {
    return (
        <PageLayout>
            <TwoColumns left={<CssChallenge/>} right={<CssChallengeSubmission loggedIn={loggedIn}/>} rightSticky={true}/>
        </PageLayout>
    )
}

export default CSSChallenge;