import React, { useEffect } from 'react'

import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';

import { TextField, Checkbox, FormControlLabel, FormGroup, Button } from '@material-ui/core'

import './OrgSettings.scss'

const OrgSettings = ({subdomain, setToastMessage}) => {

    const [orgName, setOrgName] = React.useState('');
    const [userType, setUserType] = React.useState('');
    const [emailSuffix, setEmailSuffix] = React.useState('');
    const [emailRequired, setEmailRequired] = React.useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/organizations/organization_profile/${subdomain}`)
            .then(res => res.json())
            .then(data => {
                setOrgName(data.name);
                setUserType(data.user_type);
                setEmailSuffix(data.email_suffix);
                setEmailRequired(data.email_required);
            })
    }, []);

    const saveOrgSettings = () => {
        const data = {
            name: orgName,
            userType: userType,
            emailSuffix: emailSuffix,
            emailRequired: emailRequired
        }
        fetch(`${process.env.REACT_APP_API}/organizations/organization_profile/${subdomain}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => {
                setToastMessage('Organization Settings Saved');
            })
    }

    return (
        <ShadowBox className="org-settings" title={'Organization Settings'}>
            <FormGroup className="org-settings__grid">
                <TextField
                    id="outlined-basic"
                    label="Organization Name"
                    variant="outlined"
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    required
                    fullWidth
                    className="org-settings__input"
                />
                 <TextField
                    id="outlined-basic"
                    label="Nomenclature for users"
                    variant="outlined"
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                    fullWidth
                    className="org-settings__input"
                />
                <TextField
                    id="outlined-basic"
                    label="Email Suffix"
                    variant="outlined"
                    value={emailSuffix}
                    onChange={(e) => setEmailSuffix(e.target.value)}
                    fullWidth
                    className="org-settings__input"
                />
                <FormControlLabel control={<Checkbox color="primary" checked={emailRequired} onClick={() => setEmailRequired(!emailRequired)} />} label="Organization Email Required for Sign Up" />
            </FormGroup>
            <Button 
                variant="contained" 
                color="primary" 
                className="org-settings__button"
                onClick={() => saveOrgSettings()}
                >
                Save
            </Button>
            <div style={{"clear": "both"}}></div>
        </ShadowBox>
    )
}

export default OrgSettings