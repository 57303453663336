import { useEffect, useState } from 'react';

import { getDailyStandings } from '../services/standingsService';

interface DailyStanding {
  x: string
  y: number | null
}

interface StandingsItem {
  id: string
  data: DailyStanding[]
}

interface Standings extends Array<StandingsItem> {}

export function useFetchStandings(subdomain: string) {

    const [standings, setStandings] = useState<Standings[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {

        const fetchStandings = async () => {
            try {
                const data = await getDailyStandings(subdomain);
                setStandings(data);
                setIsLoading(false);
            } catch (error) {
                setError(error instanceof Error ? error : new Error('An unknown error occurred while loading daily standings.'));
                setIsLoading(false);
            }
        };

        fetchStandings();
    }, [subdomain]);

    return { standings, isLoading, error };
}
