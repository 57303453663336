import React, {useState, useEffect} from 'react';
import { useParams} from "react-router-dom";

import { Button, Input } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Box from '@mui/material/Box';

import Modal from "../../utilComponents/modal/Modal";

import CodeChallenge from '../../components/codeChallenge/CodeChallenge';

import ProblemTable from '../../components/problemTable/ProblemTable';

import './EditProblemSet.scss';
import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';

const EditProblemSet = (props) => {

    const {problemSetId} = useParams(); 

    const [problemSet, setProblemSet] = useState({problems:[]});
    const [title, setTitle] = useState("");
    const [problemUrl, setProblemUrl] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [isSourceValid, setIsSourceValid] = useState(false);


    useEffect(() => {
        refreshProblems();
    }, [])

    const refreshProblems = () => {
        fetch(`${process.env.REACT_APP_API}/problemsets/${problemSetId}`)
            .then(response => response.json())
            .then(data => {
                setProblemSet(data);
                setTitle(data.title);
            })
    }

    const updateTitle = (e) => {
        setTitle(e.target.value);
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const saveChanges = () => {
        
    }

    const handleAddingProblem=async()=>{
        setToastMessage("Problem added");        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ url: problemUrl, problemSetId: problemSet.id})
        };
        let response = await fetch(`${process.env.REACT_APP_API}/problems`, requestOptions);
        let data = await response.json();
        if(!response.ok){
            if(data.message){
                setToastMessage(data.message);
            } else {
                setToastMessage("An error occurred while adding problem");
            }
        }
        refreshProblems();
        setIsSourceValid(false);
        setProblemUrl("");       
    }

    const handleProblemSourceInput=(e)=>{
        let url = e.target.value.replace(/^https?:\/\//, '');
        setIsSourceValid(url.includes("www.codewars.com/kata/"));
        setProblemUrl(url);
    }

    return (
        <div className="editProblemSet">
             {/* <Modal showModal={showRemoveMemberModal} setShowModal={setShowRemoveMemberModal}>
                Are you sure you want to remove this member?
                <button onClick={removeMember}>Yes</button>
                <button onClick={()=>setShowRemoveMemberModal(false)}>No</button>
            </Modal> */}
            <Snackbar
                open={!!toastMessage}
                autoHideDuration={2000}
                onClose={() => setToastMessage("")}
                message={toastMessage}
                anchorOrigin={{'vertical': 'top', 'horizontal': 'center'}  }
            />
            <h3>Edit Problem Set</h3>
            <div className="twoColumns">
                <TextField 
                    id="set-title" 
                    value={title} 
                    label="Title" 
                    variant="outlined" 
                    onChange={(e) => updateTitle(e)} 
                />
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={() => saveChanges()}
                >
                    Save Changes
                </Button>
            </div>
            <div className="twoColumns">
                <div>
                    <Box className="twoColumns">
                        <TextField 
                            id="set-title" 
                            value={problemUrl} 
                            label="Please enter problem url" 
                            variant="outlined" 
                            onChange={handleProblemSourceInput} 
                        />
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={handleAddingProblem}
                            disabled={!isSourceValid}
                        >
                            Add
                        </Button>
                    </Box>
                    { problemSet.problems.length ? (
                        <ProblemTable problemSet={problemSet} refreshProblems={refreshProblems} setToastMessage={setToastMessage} />
                    ) : (
                        <div style={{textAlign:"center"}}>No problems found</div>
                    )}
                </div>
                
                <ShadowBox title="Assign to:">
                    <FormControlLabel control={<Checkbox />} label="Pursuit-8.1" />
                    <FormControlLabel control={<Checkbox />} label="Pursuit-9.3" />
                    <FormControlLabel control={<Checkbox />} label="Pursuit-9.4" />
                </ShadowBox>
            </div>
        </div>
    )
}

export default EditProblemSet;