import React from 'react'

import'./BattingHistoryItem.scss';

const BattingHistoryItem = ({input, output, code, status, key}) => {

    const styles = {
      'Strike' : {
        color: 'red'
      },
      'Hit' : {
        color: 'blue'
      },
      'Score' : {
        color: 'green'
      }
    }

    const message = () => {
      let msg;
      switch(status){
        case "Strike":
          msg = "Steerike...";
          break;
        case "Ball":
          msg = "Eye on the ball";
          break;
        default:
        msg = "Nice hit!";
      }
      return msg;
    }

    return (
      <div key={key} className="battingHistoryItem">
        <span className="left">{message()}</span>
        {input && 
          <code>{JSON.stringify(input)}</code>
        }
        <code>{code}</code>
        {output && 
          <code> => {JSON.stringify(output)}</code>
        }
        <span className="battingHistoryItem__status" style={styles[status]}>
          {status}
        </span>
      </div>
    )
  }

export default BattingHistoryItem
