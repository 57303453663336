import React, { isValidElement, useState } from "react";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

function EmailInput({
  value,
  orgConfig,
  handleChange,
  errorsArr,
  setErrorsArr,
}) {
  const { orgName, emailSuffix, reqOrgEmail } = orgConfig;
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const formattedOrgName = orgName
    ? orgName[0].toUpperCase() + orgName.slice(1)
    : "";

  const emailPlaceholder =
    orgName !== "codetrack" && reqOrgEmail
      ? `${formattedOrgName} Email Address`
      : "Email Address";

  const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const needsToMatchOrg = (email) => {
    return email.split("@")[1].toLowerCase() === emailSuffix.toLowerCase();
  };

  const validateEmail = (email) => {
    if (!isValidEmail(email)) {
      setError(true);
      setHelperText("This is not a valid email.");
      let tempErrorsArr = errorsArr.filter((i) => i !== "email");
      setErrorsArr([...tempErrorsArr, "email"]);
      return;
    }

    if (reqOrgEmail && !needsToMatchOrg(email)) {
      setError(true);
      setHelperText(`Please use the email provided by ${orgName}.`);
      let tempErrorsArr = errorsArr.filter((i) => i !== "email");
      setErrorsArr([...tempErrorsArr, "email"]);
      return;
    }

    setError(false);
    setHelperText("");
    let tempErrorsArr = errorsArr.filter((i) => i !== "email");
    setErrorsArr([...tempErrorsArr]);
  };

  return (
    <Grid item xs={12}>
      <TextField
        variant="outlined"
        required
        fullWidth
        id="email"
        label={emailPlaceholder}
        name="email"
        autoComplete="email"
        error={error}
        helperText={helperText}
        onChange={handleChange}
        onBlur={(e) => validateEmail(e.target.value)}
        value={value}
      />
    </Grid>
  );
}

export default EmailInput;
