import { FormData } from '../types';

export const resetPassword = async (formData: FormData): Promise<{ success: boolean; error?: string }> => {
  const { email, newPassword, securityCode } = formData;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password: newPassword, securityCode })
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API}/auth/addPassword`, requestOptions);
    const result = await response.json();

    if (result.error) {
      throw new Error(result.error);
    }

    return { success: true };
  } catch (error) {
    console.error('Reset password error:', error);
    return { success: false, error: error instanceof Error ? error.message : 'An unknown error occurred' };
  }
};
