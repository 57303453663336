import { API_BASE_URL } from '../../../config'

import {CreateAccountForm} from '../types'

export const createAccount = async (accountData: CreateAccountForm) => {
  const { name, email, password, cohort, organization, subdomain } = accountData

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, email, password, cohort, organization })
  }

  try {
    const response = await fetch(`${API_BASE_URL}/auth/register`, requestOptions)
    const result = await response.json()

    if (result.error) {
      throw new Error(result.error)
    }

    localStorage.setItem('accessToken', result.accessToken)
    return {
      success: true,
      message: "Account successfully created!",
      accessToken: result.accessToken,
      organization: result.organization
    }
    
  } catch (error: unknown) {
    return {
      success: false,
      message: error instanceof Error ? error.message : "An error occurred while creating the account."
    }
  }
}
