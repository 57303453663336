import { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { login } from '../services/loginService'

import { LoginFormData } from '../types'

const useLogin = (setLoggedIn: (loggedIn: boolean) => void) => {
  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: ''
  })

  const [errors, setErrors] = useState<Partial<LoginFormData> & { general?: string }>({})

  const history = useHistory()

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return re.test(String(email).toLowerCase())
  }

  const handleChange = useCallback((
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }, [])

  const validateForm = useCallback(() => {
    const newErrors: Partial<LoginFormData> & { general?: string } = {}

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required'
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email address'
    }

    if (!formData.password) {
      newErrors.password = 'Password is required'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }, [formData])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors({})

    if (validateForm()) {
      try {
        const response = await login({ email: formData.email, password: formData.password })

        if (response?.success) {
            localStorage.setItem('accessToken', response.accessToken)
            setLoggedIn(true)
            history.push({
                pathname: '/',
                state: { toastMessage: "Welcome back to CodeTrack!" }
            })
        } else {
          throw new Error('Login failed')
        }
      } catch (error) {
        setErrors((prev) => ({ ...prev, general: 'Login failed. Please try again.' }))
      }
    }
  }

  return {
    formData,
    errors,
    handleChange,
    handleSubmit,
  }
}

export default useLogin